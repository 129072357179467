export const stores = [
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 01402-901",
    Bairro: "JARDIM PAULISTA    ",
    Rua: "Av.Brigadeiro Luis Antonio, 3126,",
    latitude: -23.5728178,
    longitude: -46.6550702,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "05019-011",
    Bairro: "Pompeia",
    Rua: "Av. Professor Alfonso Bovero, 1425        ",
    latitude: -23.5354114,
    longitude: -46.688017,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 01441-001",
    Bairro: "Jd.Paulistano",
    Rua: "Al. Gabriel Monteiro da Silva, 1351 ",
    latitude: -23.5717212,
    longitude: -46.6811898,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04511-001",
    Bairro: "VILA NOVA CONCEICAO",
    Rua: "R. Afonso Brás, 480",
    latitude: -23.595249,
    longitude: -46.66860680000001,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04555-001",
    Bairro: "BROOKLIN           ",
    Rua: "Av.Santo Amaro, 3271,",
    latitude: -23.613528,
    longitude: -46.6780036,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 02011-300",
    Bairro: "SANTANA",
    Rua: "R.Voluntários da Pátria, 1723 ",
    latitude: -23.5052063,
    longitude: -46.6267575,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "05013-000",
    Bairro: "PERDIZES           ",
    Rua: "R.Cardoso de Almeida, 472                   ",
    latitude: -23.5352824,
    longitude: -46.6665545,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "04556-500",
    Bairro: "BROOKLIN PAULISTA  ",
    Rua: "Av. Santo Amaro, 4530                          ",
    latitude: -23.6225242,
    longitude: -46.6853628,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "04010-000",
    Bairro: "VILA MARIANA       ",
    Rua: "Rua Domingos de Moraes, 486                      ",
    latitude: -23.581237,
    longitude: -46.6392058,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04702-000",
    Bairro: "SANTO AMARO",
    Rua: "Av. Santo Amaro, 5460",
    latitude: -23.6304648,
    longitude: -46.6908542,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "01451-000",
    Bairro: "JARDIM PAULISTA    ",
    Rua: "Av. Brig.Faria Lima, 2232 ",
    latitude: -23.5771128,
    longitude: -46.6880055,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 01401-002",
    Bairro: "ITAIM BIBI         ",
    Rua: "R. Joaquim Floriano,  24 Itaim Bibi",
    latitude: -23.5833388,
    longitude: -46.6705224,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04028-001",
    Bairro: "INDIANOPOLIS       ",
    Rua: "Av.Ibirapuera, 1770",
    latitude: -23.6003308,
    longitude: -46.6597843,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 05461-010",
    Bairro: "ALTO DE PINHEIROS  ",
    Rua: "Praça Panamericana, 217 ",
    latitude: -23.5538746,
    longitude: -46.7101701,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04671-011",
    Bairro: "VILA SOFIA         ",
    Rua: "R. Sócrates, 120",
    latitude: -23.6557665,
    longitude: -46.6917656,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "05641-000",
    Bairro: "VILA SUZANA        ",
    Rua: "R. Marechal Hastinfilo de Moura,  30             ",
    latitude: -23.616192,
    longitude: -46.7396055,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 06454-010",
    Bairro: "ALPHAVILE          ",
    Rua: "Alameda Madeira,  152                                       ",
    latitude: -23.4947656,
    longitude: -46.850188,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "01410-002",
    Bairro: "CERQUEIRA CESAR    ",
    Rua: "Al. Ministro Rocha Azevedo, 1136",
    latitude: -23.5662195,
    longitude: -46.66501,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 03310-000",
    Bairro: "VILA GOMES CARDIM  ",
    Rua: "Rua Serra de Japi, 647                    ",
    latitude: -23.5447899,
    longitude: -46.5690497,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 02040-070",
    Bairro: "JD. SAO PAULO      ",
    Rua: "Av. Nova Cantareira 240/248",
    latitude: -23.4880789,
    longitude: -46.6217982,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 05513-300",
    Bairro: "BUTANTA",
    Rua: "Av. Francisco Morato, 2385,",
    latitude: -23.5861238,
    longitude: -46.72206800000001,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "05676-120",
    Bairro: "JARDIM PANORAMA",
    Rua: "Av. Magalhães de Castro, Nº 6118",
    latitude: -23.6115058,
    longitude: -46.7011079,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 01317-001",
    Bairro: "BELA VISTA",
    Rua: "AV.BRIGIDEIRO LUIZ ANTONIO, 2013",
    latitude: -23.5655978,
    longitude: -46.6472334,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "04115-090",
    Bairro: "JD. VILA MARIANA   ",
    Rua: "R. Prof. Serafim Orlandi 299           ",
    latitude: -23.5878356,
    longitude: -46.6205735,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04053-042",
    Bairro: "PLANALTO PAULISTA  ",
    Rua: "Alameda Guatás, 1261                     ",
    latitude: -23.6169334,
    longitude: -46.6494442,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04563-003",
    Bairro: "CIDADE MONCOES        ",
    Rua: "R. Pe.Antonio José dos Santos, 872           ",
    latitude: -23.6102394,
    longitude: -46.6861536,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "05051-000",
    Bairro: "VILA ROMANA        ",
    Rua: "R. Tito, 639/705              ",
    latitude: -23.5284806,
    longitude: -46.6983813,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Guarujá",
    Estado: "SP",
    CEP: "11440-000",
    Bairro: "TEJEREBA           ",
    Rua: "Av.Dom Pedro I, 195",
    latitude: -23.9883215,
    longitude: -46.2484854,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Santana De Parnaiba",
    Estado: "SP",
    CEP: "06541-005",
    Bairro: "ALPHAVILLE         ",
    Rua: "Av. Marte 624             ",
    latitude: -23.4620253,
    longitude: -46.8757862,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04005-001",
    Bairro: "PARAISO",
    Rua: "Rua Abilio Soares, 386 ",
    latitude: -23.5741908,
    longitude: -46.6456773,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: " 04627-001",
    Bairro: "SANTO AMARO",
    Rua: "Av. Washington Luiz, 3919  ",
    latitude: -23.6445109,
    longitude: -46.67388589999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Marilia",
    Estado: "SP",
    CEP: " 17501-090",
    Bairro: "BARBOSA",
    Rua: "Rua Bandeirantes, 430",
    latitude: -22.2195693,
    longitude: -49.94359559999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "04515-010",
    Bairro: "MOEMA ",
    Rua: "Av. Lavandisca, 249/263    ",
    latitude: -23.6004842,
    longitude: -46.6669254,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Santo André      ",
    Estado: "SP",
    CEP: " 09090-060",
    Bairro: "JARDIM",
    Rua: "R. das Figueiras, 1021",
    latitude: -23.6488258,
    longitude: -46.54011389999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Sorocaba",
    Estado: "SP",
    CEP: " 18013-280",
    Bairro: "ALTO DA BOA VISTA",
    Rua: "Av. Eng. Carlos Reinaldo Mendes      ",
    latitude: -23.4813726,
    longitude: -47.4206277,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Valinhos",
    Estado: "SP",
    CEP: " 13270-600",
    Bairro: "JARDIM EUROPA      ",
    Rua: "Rua Adhemar de Barros -",
    latitude: -22.982258,
    longitude: -47.007802,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Campinas",
    Estado: "SP",
    CEP: "13070-011",
    Bairro: "CASTELO",
    Rua: "Av. Alberto Samento, 775",
    latitude: -22.9050824,
    longitude: -47.0613327,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Campinas",
    Estado: "SP",
    CEP: "13025-155",
    Bairro: "CAMBUI",
    Rua: "Av. Gal Osório, 1844",
    latitude: -22.901429,
    longitude: -47.0523465,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Taubaté",
    Estado: "SP",
    CEP: "12031-180",
    Bairro: "INDEPENDENCIA      ",
    Rua: "Av. Independência, 821",
    latitude: -23.0384689,
    longitude: -45.579682,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Vicente",
    Estado: "SP",
    CEP: "11320-000",
    Bairro: "são vicente",
    Rua: "Av.Presidente Wilson, 136",
    latitude: -23.9706764,
    longitude: -46.3706101,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Jose Dos Campos",
    Estado: "SP",
    CEP: "12246-260",
    Bairro: "PARQUE RESIDENCIAL A",
    Rua: " Av Salmao ,Quadra 37",
    latitude: -16.0251404,
    longitude: -48.05912379999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Jundiai",
    Estado: "SP",
    CEP: " 13209-011",
    Bairro: "CETRO",
    Rua: " AV NOVE DE JULHO 3535",
    latitude: -23.2020179,
    longitude: -46.8919089,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Itu",
    Estado: "SP",
    CEP: " 13301-000",
    Bairro: "Liberdade",
    Rua: "AV DR OTAVIANO PEREIRA MENDES, 469",
    latitude: -23.2608277,
    longitude: -47.2961525,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Atibaia",
    Estado: "SP",
    CEP: "12941-650",
    Bairro: "Jardim Suiça",
    Rua: "AL PROFESSOR LUCAS NOGUEIRA GARCEZ, 2435",
    latitude: -23.1292502,
    longitude: -46.5538468,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Sorocaba",
    Estado: "SP",
    CEP: "18047-600",
    Bairro: "Jardim portal da colina",
    Rua: "AV ANTONIO CARLOS COMITRE 945",
    latitude: -23.522903,
    longitude: -47.464822,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Praia Grande",
    Estado: "SP",
    CEP: " 11701-340",
    Bairro: "Canto do forte",
    Rua: "AV. MAL MALLET, 1519",
    latitude: -24.0047687,
    longitude: -46.4085865,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Itu",
    Estado: "SP",
    CEP: "13309-050",
    Bairro: "Vila Nova",
    Rua: "Rua Prudente de Moraes, 210",
    latitude: -23.2744769,
    longitude: -47.291702,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Jose Dos Campos",
    Estado: "SP",
    CEP: " 12242-000",
    Bairro: "JARDIM DAS COLINAS ",
    Rua: "Av. São João, 2200",
    latitude: -23.2051607,
    longitude: -45.9077953,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Pindamonhangaba",
    Estado: "SP",
    CEP: " 12420-010",
    Bairro: "ALTO DO CARDOSO    ",
    Rua: "Rua N. Sra. do Bomsucesso, 1650 -",
    latitude: -22.9400194,
    longitude: -45.4623879,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Campos Do Jordão",
    Estado: "SP",
    CEP: "12460-000",
    Bairro: "CENTRO",
    Rua: "Av. Dr. Januário Miraglia, 1772 ",
    latitude: -22.7364395,
    longitude: -45.5883875,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Campinas",
    Estado: "SP",
    CEP: "13084-153",
    Bairro: "BARAO GERALDO",
    Rua: "Av. Albino Jose Barbosa de Oliveira, 1340",
    latitude: -22.826429,
    longitude: -47.0790614,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Carlos",
    Estado: "SP",
    CEP: " 13561-352",
    Bairro: "Parque Faber castel II",
    Rua: "R.PASSEIO DOS FLAMBOYANTS,200",
    latitude: -22.0163727,
    longitude: -47.9163785,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Itu",
    Estado: "SP",
    CEP: "13300-000",
    Bairro: "Jardim paraiso",
    Rua: "ROD. MARECHAL RONDON, KM 105",
    latitude: -22.2281052,
    longitude: -49.97418709999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Ribeirão Preto",
    Estado: "SP",
    CEP: "14025-320",
    Bairro: "JD CANADA          ",
    Rua: "Av. Professor João Fiusa",
    latitude: -21.216463,
    longitude: -47.8054752,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Santos",
    Estado: "SP",
    CEP: "11030-600",
    Bairro: "APARECIDA          ",
    Rua: "Av.Epitácio Pessoa, 542",
    latitude: -23.9592201,
    longitude: -46.3317787,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Campinas",
    Estado: "SP",
    CEP: "13105-500",
    Bairro: "SOUZAS",
    Rua: "Av. Antonio Carlos Couto de Barros, 365",
    latitude: -22.8881136,
    longitude: -46.9798911,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Bertioga",
    Estado: "SP",
    CEP: " 11261-201",
    Bairro: "LOT RIVIERA S.LOUREN",
    Rua: "Av. Aprovada, 329, Quadra C,    LOTE 01 mod 27",
    latitude: -23.7906322,
    longitude: -46.0337362,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Paulo - Interior",
    Estado: "SP",
    CEP: "13419-138",
    Bairro: "BAIRRO ALTO        ",
    Rua: "Rua Visconde do Rio Branco, 583",
    latitude: -21.2922457,
    longitude: -50.3428431,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Guarujá",
    Estado: "SP",
    CEP: " 11440-000",
    Bairro: "TEJEREBA           ",
    Rua: "Av.Dom Pedro I, 195",
    latitude: -23.9883215,
    longitude: -46.2484854,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São José Do Rio Preto",
    Estado: "SP",
    CEP: "15025-000",
    Bairro: "jardim alto rio preto",
    Rua: " A. Bady Bassit, 5300",
    latitude: -20.8219632,
    longitude: -49.40106979999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Indaiatuba",
    Estado: "SP",
    CEP: " 13334-085",
    Bairro: "CIDADE NOVA I      ",
    Rua: "Av. Presidente Vargas c/ Av. dos Trabalhadores",
    latitude: -23.086682,
    longitude: -47.180981,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São José Do Rio Preto",
    Estado: "SP",
    CEP: "15061-768",
    Bairro: "RESIDENCIAL JARDINS",
    Rua: "Av. Miguel Damha, 1805",
    latitude: -20.8101534,
    longitude: -49.3407966,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Santos",
    Estado: "SP",
    CEP: " 11065-200",
    Bairro: "Pompeia",
    Rua: "Av. Presidente Wilson, 94/ 96",
    latitude: -23.9678281,
    longitude: -46.3430796,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Bauru",
    Estado: "SP",
    CEP: " 17010-250",
    Bairro: "JARDIM ESTORIL     ",
    Rua: "Rua Azarias Leite, 1940",
    latitude: -22.3377984,
    longitude: -49.07257980000001,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Bauru",
    Estado: "SP",
    CEP: " 17012-055",
    Bairro: "VL STA TEREZA      ",
    Rua: "Rua Araujo Leite, 2250",
    latitude: -22.3308349,
    longitude: -49.0667699,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Campinas",
    Estado: "SP",
    CEP: "13073-300",
    Bairro: "JD GUANABARA       ",
    Rua: "Av. Barão de Itapura, 2233",
    latitude: -22.8860226,
    longitude: -47.0598986,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Santo André",
    Estado: "SP",
    CEP: " 09090-060",
    Bairro: "JARDIM",
    Rua: "Rua das Goiabeiras, 359",
    latitude: -23.6489709,
    longitude: -46.5410626,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "São Caetano Do Sul",
    Estado: "SP",
    CEP: " 09541-001",
    Bairro: "SANTA PAULA        ",
    Rua: "Rua Maranhão, 975",
    latitude: -23.6209475,
    longitude: -46.5636485,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Botucatu",
    Estado: "SP",
    CEP: " 18600-200",
    Bairro: "CENTRO",
    Rua: "Rua Coronel Fonseca, 444, centro",
    latitude: -22.8826986,
    longitude: -48.451791,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Ourinhos",
    Estado: "SP",
    CEP: "19907-510",
    Bairro: "NOVA OURINHOS      ",
    Rua: "Av. Luiz Saldanha Rodrigues, 1591",
    latitude: -22.9774973,
    longitude: -49.88602119999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Piracicaba",
    Estado: "SP",
    CEP: "13400-853",
    Bairro: "CENTRO",
    Rua: "AV DR PAULO DE MORAES,1216",
    latitude: -22.73386,
    longitude: -47.658533,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: " 71615-560",
    Bairro: "LAGO SUL",
    Rua: "SUIS/CI. QI 05 Conj. F n.80  subsolo    Centro Coml. Gilberto Salomão",
    latitude: -15.8414298,
    longitude: -47.8767513,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: "70381-515",
    Bairro: "ASA SUL",
    Rua: "SCRS 516 Bloco A Ljs 45/46   ",
    latitude: -15.7991515,
    longitude: -47.8877005,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: " 70337-010",
    Bairro: "ASA SUL",
    Rua: "SHCS 304/305 s/n Bloco “A” MC",
    latitude: -15.8071501,
    longitude: -47.8956077,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: " 70355-400",
    Bairro: "ASA SUL",
    Rua: "SHCS 308/309 s/n  Bloco “A”",
    latitude: -15.8202434,
    longitude: -47.9045093,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: "70239-400",
    Bairro: "ASA SUL",
    Rua: "EQS 406/407  s/n    Bloco A",
    latitude: -15.8198603,
    longitude: -47.8945425,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: "70835-400",
    Bairro: "ASA NORTE",
    Rua: "EQN 404/405  s/n Bloco A ",
    latitude: -15.7730281,
    longitude: -47.8753095,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: " 71505-000",
    Bairro: "LAGO NORTE",
    Rua: "SHIN s/n, Bloco A Trecho 1",
    latitude: -15.7144534,
    longitude: -47.8966364,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: "70740-546",
    Bairro: "ASA NORTE",
    Rua: "EQN 508/509 Bloco A ",
    latitude: -15.7620021,
    longitude: -47.89055099999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: " 71505-000",
    Bairro: "SUDOESTE",
    Rua: "CCSW 06 Ljs 4/5",
    latitude: -15.797302,
    longitude: -47.9286266,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: "71010-711",
    Bairro: "GHARÁ",
    Rua: "SRIA - Área Especial K n.02",
    latitude: -15.7975154,
    longitude: -47.89188739999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Brasilia",
    Estado: "BSB",
    CEP: "71925-180",
    Bairro: "AGUAS CLARAS",
    Rua: "Quadra 206 Lote 02 Praça Tuim",
    latitude: -15.8427244,
    longitude: -48.0227466,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Goiania",
    Estado: "GO",
    CEP: "74280-295",
    Bairro: "SETOR BUENO        ",
    Rua: "Rua T68 s/n, Quadra 135 Lote 08,",
    latitude: -16.7010209,
    longitude: -49.2739638,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Goiania",
    Estado: "GO",
    CEP: "74810-030",
    Bairro: "JARDIM GOIAS       ",
    Rua: "Av. e Qd B-03 S/N",
    latitude: -16.696277,
    longitude: -49.2439061,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Goiania",
    Estado: "GO",
    CEP: " 74230-042",
    Bairro: "ST BUENO           ",
    Rua: "Av. T5 s/n Quadra 151 Lote 01",
    latitude: -16.7105953,
    longitude: -49.2692735,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Goiania",
    Estado: "GO",
    CEP: " 74115-030",
    Bairro: "ST OESTE           ",
    Rua: "Av. República do Líbano s/nº  Quadra 56",
    latitude: -16.6779734,
    longitude: -49.2714309,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Uberlandia",
    Estado: "MG",
    CEP: " 38411-106",
    Bairro: "MORADA DA COLINA   ",
    Rua: " AV NICOMEDES ALVES DOS SANTOS,2222",
    latitude: -18.9418742,
    longitude: -48.2742623,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Jaboatão Dos Guararapes",
    Estado: "PE",
    CEP: " 54430-770",
    Bairro: "JABOATÃO DO GUARARÁ",
    Rua: "AV BERNARDO VIEIRA DE MELO 1372   ",
    latitude: -8.1688125,
    longitude: -34.920874,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 52020-220",
    Bairro: "GRACAS",
    Rua: "Av. Conselheiro Rosa e Silva, 614",
    latitude: -8.046336999999999,
    longitude: -34.8964658,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 52060-140",
    Bairro: "PANAMIRIM",
    Rua: "RUA DESEMBARG.GOES CAVALCANTE, 261",
    latitude: -8.0318723,
    longitude: -34.9087055,
  },
  {
    Parceiro: "Minuto Gpa",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 50710-435",
    Bairro: "MADALENA",
    Rua: "RUA JOSÉ BONIFÁCIO, 435",
    latitude: -8.047359800000001,
    longitude: -34.9069172,
  },
  {
    Parceiro: "Minuto Gpa",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 51111-010",
    Bairro: "BOA VIAGEM",
    Rua: "AV. CONSELHEIRO AGUIAR , 2034",
    latitude: -8.107700099999999,
    longitude: -34.8901326,
  },
  {
    Parceiro: "Minuto Gpa",
    Cidade: "Recife",
    Estado: "PE",
    CEP: "51020-010",
    Bairro: "BOA VIAGEM",
    Rua: "RUA DOS NAVEGANTES 992",
    latitude: -8.123629599999999,
    longitude: -34.8972553,
  },
  {
    Parceiro: "Minuto Gpa",
    Cidade: "Recife",
    Estado: "PE",
    CEP: "52011-050",
    Bairro: "GRACAS",
    Rua: "RUA AMÉLIA , 685",
    latitude: -8.045323400000001,
    longitude: -34.9014879,
  },
  {
    Parceiro: "Minuto Gpa",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 50610-320",
    Bairro: "MADALENA",
    Rua: "RUA MENEZES DRUMOND, 170",
    latitude: -8.0507426,
    longitude: -34.9097132,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Jaboatão Dos Guararapes",
    Estado: "PE",
    CEP: "54430-350",
    Bairro: "CANDEIAS",
    Rua: "R ANIBAL RIBEIRO VAREJAO,",
    latitude: -8.19617,
    longitude: -34.920599,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 50731-000",
    Bairro: "IPUTINGA",
    Rua: "AVENIDA CAXANGA 2900",
    latitude: -8.043536099999999,
    longitude: -34.9328311,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 51021-020",
    Bairro: "BOA VIAGEM",
    Rua: "AV. CONSELHEIRO AGUIAR, 4483",
    latitude: -8.1266514,
    longitude: -34.8995361,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Recife",
    Estado: "PE",
    CEP: " 52021-180",
    Bairro: "ESPINHEIRO",
    Rua: "AVENIDA JOAO DE BARROS 1497",
    latitude: -8.041717199999999,
    longitude: -34.8915942,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Recife",
    Estado: "PE",
    CEP: "53030-010",
    Bairro: "BAIRRO NOVO / OLINDA",
    Rua: "AVENIDA PRES GETULIO VARGAS 411",
    latitude: -8.0079672,
    longitude: -34.8423244,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Recife",
    Estado: "PE",
    CEP: "50070-070",
    Bairro: "BOA VISTA",
    Rua: "RUA DOM BOSCO,913",
    latitude: -8.0579207,
    longitude: -34.8947781,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "João Pessoa",
    Estado: "PB",
    CEP: " 58040-000",
    Bairro: "MIRAMAR",
    Rua: "AV EPITACIO PESSOA,4200           ",
    latitude: -7.1201852,
    longitude: -34.8321472,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "João Pessoa",
    Estado: "PB",
    CEP: "58037-000",
    Bairro: "BESSA",
    Rua: "AV GOV FLAVIO RIBEIRO COUTINHO 600",
    latitude: -7.0969332,
    longitude: -34.8385495,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "João Pessoa",
    Estado: "PB",
    CEP: " 58052-200",
    Bairro: "CID. UNIVERSITÁRIA",
    Rua: "R WALFREDO MACEDO BRANDAO JARDIM CIDADE UN",
    latitude: -7.1586512,
    longitude: -34.8387597,
  },
  {
    Parceiro: "Supermercado Guará",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60811-900",
    Bairro: "Edson queiroz",
    Rua: "AV. WASHINGTON SOARES, 85 SALA A-3 AGUA FR",
    latitude: -3.7777567,
    longitude: -38.4811354,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60055-401",
    Bairro: "AGUANAMBI",
    Rua: "AV.AGUANAMBI, 1393 FATIMA   ",
    latitude: -3.748629,
    longitude: -38.5229264,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60040-620",
    Bairro: "FATIMA",
    Rua: "RUA DR COSTA ARAUJO 1132          ",
    latitude: -3.7466089,
    longitude: -38.5315148,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60150-161",
    Bairro: "CENTER UM",
    Rua: "AVENIDA SANTOS DUMONT, 3130       ",
    latitude: -3.7372763,
    longitude: -38.4969825,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60150-160",
    Bairro: " SAO JOAO",
    Rua: "AVENIDA SANTOS DUMONT, 1169       ",
    latitude: -3.7306636,
    longitude: -38.5131575,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60160-230",
    Bairro: "SHOPPING ALDEOTA",
    Rua: "AV. DOM LUIZ 500 LOJA 40          ",
    latitude: -3.7340781,
    longitude: -38.4968605,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60811-340",
    Bairro: "Agua fria",
    Rua: "AV. WASHINGTON SOARES, 4040 S 1-2-3",
    latitude: -3.769544999999999,
    longitude: -38.4824579,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60140-000",
    Bairro: " JULIO VENTURA",
    Rua: "RUA BARBARA DE ALENCAR, 1887 L- 01",
    latitude: -3.7399375,
    longitude: -38.5075504,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60175-650",
    Bairro: "COCÓ",
    Rua: "AV ENG SANTANA JUNIOR, 2277 L 55  ",
    latitude: -3.743248,
    longitude: -38.4867037,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60135-102",
    Bairro: "DINISIO TORRES",
    Rua: "AV. ANTONIO SALES, 2477           ",
    latitude: -3.7455756,
    longitude: -38.5015517,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60165-081",
    Bairro: " MUCURIPE",
    Rua: "AV. DA ABOLICAO, 3790",
    latitude: -3.7277981,
    longitude: -38.4861642,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60170-251",
    Bairro: " VIRGILO TAVORA",
    Rua: "AV. SENADOR VIRGILIO TAVORA 2270  ",
    latitude: -3.746666899999999,
    longitude: -38.4966291,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60811-341",
    Bairro: "BUENA VISTA",
    Rua: "AV. WASHINGTON SOARES,1500 LJ 03,04",
    latitude: -3.7721722,
    longitude: -38.4827952,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60325-001",
    Bairro: " SAO GERALDO",
    Rua: "AV. BEZERRA DE MENEZES 1498/1548  ",
    latitude: -3.733657,
    longitude: -38.5586236,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: " 60833-005",
    Bairro: "EDSON queiroz",
    Rua: "AV WASHIGTON SOARES, 4075",
    latitude: -3.7927928,
    longitude: -38.4799512,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60411-260",
    Bairro: "EXTRA PERTO RODOVIARIA",
    Rua: "AV DEPUTADO OSVALDO STUDART, 600 FATIMA",
    latitude: -3.7555456,
    longitude: -38.530405,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60165-081",
    Bairro: "MEIRELES",
    Rua: "AVENIDA DA ABOLICAO 2900          ",
    latitude: -3.7274751,
    longitude: -38.4937798,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 24350-310",
    Bairro: "ITAIPU",
    Rua: "ESTR.FRANCISCO DA CRUZ NUNES,6501",
    latitude: -22.9509918,
    longitude: -43.0292622,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Petropolis",
    Estado: "RJ",
    CEP: " 25730-740",
    Bairro: "ITAIPAVA",
    Rua: "ESTRADA UNIAO E INDUSTRIA, 11711",
    latitude: -22.3891056,
    longitude: -43.1322525,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "20270-231",
    Bairro: "Tijuca",
    Rua: "RUA DR SATAMINI 164",
    latitude: -22.9189255,
    longitude: -43.2174978,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22230-060",
    Bairro: "Botafogo",
    Rua: "RUA MARQUES DE ABRANTES 165",
    latitude: -22.9388541,
    longitude: -43.1776285,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22461-000",
    Bairro: "JD BOTANICO",
    Rua: "RUA JARDIM BOTANICO, 680",
    latitude: -22.9648204,
    longitude: -43.21838229999999,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22640-101",
    Bairro: "BARRA",
    Rua: "AV DAS AMERICAS ,2000",
    latitude: -22.9983501,
    longitude: -43.3346767,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "22640-100",
    Bairro: "RECREIO",
    Rua: "AV. DAS AMERICAS, 19019 LOJA 108",
    latitude: -23.0219875,
    longitude: -43.4884228,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22231-000",
    Bairro: "BOTAFOGO",
    Rua: "R. BARAO DE ITAMBI, 50",
    latitude: -22.9406397,
    longitude: -43.1809971,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Niteroi",
    Estado: "RJ",
    CEP: "24210-445",
    Bairro: "INGA",
    Rua: "R DR PAULO ALVES, 42",
    latitude: -22.9042892,
    longitude: -43.1218757,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Niteroi",
    Estado: "RJ",
    CEP: "24220-000",
    Bairro: "ICARAI",
    Rua: "AV. SETE DE SETEMBRO, 291",
    latitude: -22.9032575,
    longitude: -43.0996049,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "22021-010",
    Bairro: "Copacabana",
    Rua: "RUA MINISTRO VIVEIROS DE CASTRO 38",
    latitude: -22.9634135,
    longitude: -43.1757354,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22050-002",
    Bairro: "Copacabana",
    Rua: "AVENIDA NOSSA SRA DE COPACABANA 493",
    latitude: -22.9673395,
    longitude: -43.1812092,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22031-070",
    Bairro: "Copacabana",
    Rua: "R SIQUEIRA CAMPOS, 97/97",
    latitude: -22.9679307,
    longitude: -43.1860598,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22061-000",
    Bairro: "Copacabana",
    Rua: "RUA POMPEU LOUREIRO, 15",
    latitude: -22.9729515,
    longitude: -43.191907,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "22070-011",
    Bairro: "Copacabana",
    Rua: "AV N SRA DE COPACABANA, 1162",
    latitude: -22.9805502,
    longitude: -43.191356,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22430-220",
    Bairro: "LEBLON",
    Rua: "RUA JOSE LINHARES 245",
    latitude: -22.9809586,
    longitude: -43.2222956,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "22270-000",
    Bairro: "BOTAFOGO",
    Rua: "RUA VOLUNTARIOS DA PATRIA 311",
    latitude: -22.9542369,
    longitude: -43.1918787,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "22011-002",
    Bairro: "COPACABANA- A. VERDE",
    Rua: "RUA BARATA RIBEIRO,189 LJ A E B",
    latitude: -22.9654082,
    longitude: -43.1803107,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22060-001",
    Bairro: "COPACABANA- M. LEMOS",
    Rua: "AV. NOSSA SRA DE COPACABANA, 1017",
    latitude: -22.977696,
    longitude: -43.1902845,
  },
  {
    Parceiro: "Pão de Açucar",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22270-000",
    Bairro: "BOTAFOGO",
    Rua: "RUA VOLUNTARIO DA PATRI 213",
    latitude: -22.9532088,
    longitude: -43.1889221,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Petrópolis",
    Estado: "RJ",
    CEP: " 25650-021",
    Bairro: "QUITANDINHA",
    Rua: "RUA GENERAL RONDON,1015",
    latitude: -22.5344456,
    longitude: -43.2090622,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Petrópolis",
    Estado: "RJ",
    CEP: "25635-530",
    Bairro: "ALTO DA SERRA PETROPOLIS",
    Rua: "RUA TEREZA, 1415 A",
    latitude: -22.5237746,
    longitude: -43.1708801,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Teresopolis",
    Estado: "RJ",
    CEP: " 25953-003",
    Bairro: "VARZEA",
    Rua: "AV. ALM. LUCIO MEIRA, 800",
    latitude: -22.4123642,
    longitude: -42.9664876,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "São João De Meriti",
    Estado: "RJ",
    CEP: "25515-270",
    Bairro: "CENTRO",
    Rua: "RUA SAO PEDRO, 57",
    latitude: -22.802116,
    longitude: -43.369032,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Nova Iguaçu",
    Estado: "RJ",
    CEP: " 26210-010",
    Bairro: "CENTRO",
    Rua: "AV NILO PECANHA, 197 CENTRO",
    latitude: -22.7587669,
    longitude: -43.4492554,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Queimados",
    Estado: "RJ",
    CEP: "26383-080",
    Bairro: "VILA DAS MANGUEIRAS",
    Rua: "R. DR. ELOY TEIXEIRA, 370",
    latitude: -22.7160319,
    longitude: -43.5550352,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "20560-000",
    Bairro: "Vila ISABEL",
    Rua: "AV 28 DE SETEMBRO 431A E 433",
    latitude: -22.9169407,
    longitude: -43.2496061,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 21021-522",
    Bairro: "ROCHA MIRANDA",
    Rua: "AVENIDA DOS ITALIANOS,450",
    latitude: -22.853313,
    longitude: -43.3493429,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "21021-522",
    Bairro: "OLARIA",
    Rua: "RUA LEOPOLDINA REGO 666",
    latitude: -22.8422495,
    longitude: -43.2676071,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 21331-260",
    Bairro: "BENTO RIBEIRO",
    Rua: "RUA JOAO VICENTE 1335",
    latitude: -22.8640535,
    longitude: -43.3658558,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Magé",
    Estado: "RJ",
    CEP: " 25055-550",
    Bairro: "PIABETA",
    Rua: "R SANTA ELISA, 90 PIABETA",
    latitude: -22.6112282,
    longitude: -43.1778567,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 21220-700",
    Bairro: "VILA DA PENHA",
    Rua: "Av Meriti 2349",
    latitude: -22.8390668,
    longitude: -43.3120163,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Petropolis",
    Estado: "RJ",
    CEP: "25620-100",
    Bairro: "CENTRO",
    Rua: "RUA PAULO BARBOSA 161/201 CENTRO",
    latitude: -22.5088365,
    longitude: -43.1705538,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 21330-680",
    Bairro: "VL VALQUEIRE",
    Rua: "R DAS ROSAS, 52",
    latitude: -22.8788113,
    longitude: -43.3635921,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "21230-350",
    Bairro: "IRAJA II",
    Rua: "ESTR DA AGUA GRANDE, 713",
    latitude: -22.8274331,
    longitude: -43.3213927,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "21210-672",
    Bairro: "VL DA PENHA",
    Rua: "AVENIDA BRAZ DE PINA, 904",
    latitude: -22.8383394,
    longitude: -43.31201960000001,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Petropolis",
    Estado: "RJ",
    CEP: "25720-365",
    Bairro: "CORREAS",
    Rua: "PCA LUIZ FURTADAO DA ROSA 32",
    latitude: -22.4555855,
    longitude: -43.1117129,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Cabo Frio",
    Estado: "RJ",
    CEP: "28915-550",
    Bairro: "JARDIM FLAMBOYANT",
    Rua: "AV  CENTRAL, 100 CENTRO",
    latitude: -22.8816799,
    longitude: -42.0313188,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 20910-060",
    Bairro: " SAO CRISTOVÃO",
    Rua: "R S LUIZ GONZAGA,122 S",
    latitude: -22.8999407,
    longitude: -43.2239679,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22210-001",
    Bairro: "CENTRO",
    Rua: "LARGO DO MACHADO 19/23",
    latitude: -22.9318113,
    longitude: -43.1781015,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio Das Ostras",
    Estado: "RJ",
    CEP: " 28890-000",
    Bairro: "JARDIM CAMPOMAR",
    Rua: "RUA RAUL SEIXAS",
    latitude: -22.5337299,
    longitude: -41.9630727,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "20230-015",
    Bairro: "BAIRRO DE FATIMA",
    Rua: "RUA RIACHUELO,208 E 220",
    latitude: -22.9148319,
    longitude: -43.1876292,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 20250-490",
    Bairro: "RIO COMPRIDO",
    Rua: "RUA ARISTIDES LOBO,234 E 236",
    latitude: -22.9238042,
    longitude: -43.2091462,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Macae",
    Estado: "RJ",
    CEP: "27937-590",
    Bairro: "RIVIERA",
    Rua: "AV.CARLOS AUGUSTO TINOCO GARCIA,85",
    latitude: -22.3973414,
    longitude: -41.7910392,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Niteroi",
    Estado: "RJ",
    CEP: "24020-004",
    Bairro: "CENTRO",
    Rua: "R. VISCONDE DE RIO BRANCO 511/517",
    latitude: -22.8953619,
    longitude: -43.124502,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Macae",
    Estado: "RJ",
    CEP: "27910-340",
    Bairro: "CENTRO",
    Rua: "RUA SILVA JARDIM, 505",
    latitude: -22.3772308,
    longitude: -41.7820734,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Araruama",
    Estado: "RJ",
    CEP: "28979-120",
    Bairro: "CENTRO",
    Rua: "RUA MEXICO, 160",
    latitude: -22.8713656,
    longitude: -42.3351795,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Búzios",
    Estado: "RJ",
    CEP: " 28950-000",
    Bairro: "PORTO BELO",
    Rua: "AV JOSE BENTO RIBEIRO DANTAS 1313",
    latitude: -22.7743359,
    longitude: -41.9242086,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Campos Dos Goytacazes",
    Estado: "RJ",
    CEP: "28013-140",
    Bairro: "TURFE CAMPOS",
    Rua: "R DR FELIPE UEBE 451/469",
    latitude: -21.76912,
    longitude: -41.3143838,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "São Gonçalo",
    Estado: "RJ",
    CEP: " 24420-465",
    Bairro: "ROCHA ",
    Rua: "AV. EDSON, 3240",
    latitude: -22.8235045,
    longitude: -43.0482459,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "20530-002",
    Bairro: " TIJUCA",
    Rua: "AV. CONDE DE BONFIM, 186",
    latitude: -22.9236177,
    longitude: -43.227852,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Cabo Frio",
    Estado: "RJ",
    CEP: ", 28909-001",
    Bairro: "CENTRO",
    Rua: "RUA TEIXEIRA DE SOUZA,1601",
    latitude: -22.8885916,
    longitude: -42.0311487,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "São Pedro Da Aldeia",
    Estado: "RJ",
    CEP: " 28940-000",
    Bairro: "CENTRO",
    Rua: "AV. GETULIO VARGAS, 08",
    latitude: -22.8382248,
    longitude: -42.1034564,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: " 22031-070",
    Bairro: "COPACABANA",
    Rua: "RUA SIQUEIRA CAMPOS,143 LOJAS 48/40",
    latitude: -22.9664787,
    longitude: -43.1881081,
  },
  {
    Parceiro: "Mercado Extra",
    Cidade: "Rio De Janeiro",
    Estado: "RJ",
    CEP: "20230-260",
    Bairro: "SANTANA",
    Rua: "RUA SANTANA, 157 CENTRO",
    latitude: -22.9089054,
    longitude: -43.1931469,
  },
  {
    Parceiro: "SUPERMERCADO GOIABEIRAS",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75400000",
    Bairro: "VILA SAO SEBASTIAO",
    Rua: "DOMINGOS NETO",
    latitude: -16.3650698,
    longitude: -49.4938711,
  },
  {
    Parceiro: "TATICO - GARAVELO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74932120",
    Bairro: "SETOR GARAVELO",
    Rua: "26 C",
    latitude: -16.7726031,
    longitude: -49.3377852,
  },
  {
    Parceiro: "TATICO - CENTRO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74055140",
    Bairro: "SETOR CENTRAL",
    Rua: "CONTORNO",
    latitude: -16.6686504,
    longitude: -49.2543026,
  },
  {
    Parceiro: "TATICO - T7",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74270020",
    Bairro: "JARDIM AMERICA",
    Rua: "C205",
    latitude: -16.6970284,
    longitude: -49.2847621,
  },
  {
    Parceiro: "TATICO - PORTAL",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74450010",
    Bairro: "CAPUAVA",
    Rua: "ANHANGUERA",
    latitude: -16.6538932,
    longitude: -49.33029819999999,
  },
  {
    Parceiro: "TATICO - CAMPINAS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74550060",
    Bairro: "SETOR CENTRO OESTE",
    Rua: "DO COMERCIO",
    latitude: -16.6673535,
    longitude: -49.2861597,
  },
  {
    Parceiro: "TATICO - PAPILON",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74920760",
    Bairro: "JARDIM BELA MORADA",
    Rua: "PEDRO LUIZ RIBEIRO",
    latitude: -16.7692341,
    longitude: -49.2730807,
  },
  {
    Parceiro: "SUPERMERCADO MAIS VOCÊ",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75405170",
    Bairro: "PARQUE SAO JORGE",
    Rua: "BERNARDO SAYÃO",
    latitude: -16.3807657,
    longitude: -49.48630310000001,
  },
  {
    Parceiro: "SUPERMERCADO PORTAL - INHUMAS",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75400483",
    Bairro: "CENTRO",
    Rua: "BERNARDO SAYÃO",
    latitude: -16.373129,
    longitude: -49.4916776,
  },
  {
    Parceiro: "SUPERMERCADO BARÃO",
    Cidade: "Ipora",
    Estado: "GO",
    CEP: "76200000",
    Bairro: "SETOR DOS FUNCIONARIOS",
    Rua: "JACINTO MOREIRA",
    latitude: -16.6689764,
    longitude: -49.28034090000001,
  },
  {
    Parceiro: "SUPERMERCADO MARQUES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74355516",
    Bairro: "JARDIM ITAIPU",
    Rua: "VIEIRA SANTOS",
    latitude: -16.7819317,
    longitude: -49.3632554,
  },
  {
    Parceiro: "SUPER ECONÔMICO SUPERMERCADO EIRELI",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74620030",
    Bairro: "SETOR MORAIS",
    Rua: "LAURICIO PEDRO RASMUSSEM",
    latitude: -16.6658929,
    longitude: -49.2280869,
  },
  {
    Parceiro: "SUPERMIX COMERCIO DE SECOS E MOLHADOS EIRELI ME",
    Cidade: "Trindade",
    Estado: "GO",
    CEP: "75384346",
    Bairro: "SETOR PONTA KAYANA",
    Rua: "DAS PAINEIRAS",
    latitude: -16.6409271,
    longitude: -49.4385549,
  },
  {
    Parceiro: "SUPER ZE TRINDADE",
    Cidade: "Trindade",
    Estado: "GO",
    CEP: "75389015",
    Bairro: "SETOR ANA ROSA",
    Rua: "CONSTANTINO XAVIER",
    latitude: -16.6668855,
    longitude: -49.4829226,
  },
  {
    Parceiro: "SUPER ECONÔMICO NOVO MUNDO EIRELI",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74715400",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "PONTA GROSSA",
    latitude: -16.6794382,
    longitude: -49.2182748,
  },
  {
    Parceiro: "SUPERMERCADO PREDILETOS MOLHADOS E SECOS EIRELI",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74356038",
    Bairro: "RESIDENCIAL ITAIPU",
    Rua: "RI 14",
    latitude: -16.78351,
    longitude: -49.36626,
  },
  {
    Parceiro: "GOIABÃO SUPERMERCADO - GOIÁ",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74485320",
    Bairro: "GOIA",
    Rua: "FELIPE CAMARAO",
    latitude: -16.684863,
    longitude: -49.3318468,
  },
  {
    Parceiro: "SUPER MARQUES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74484225",
    Bairro: "PARQUE PARAISO",
    Rua: "SALOMAO JARDIM",
    latitude: -16.7039187,
    longitude: -49.3552455,
  },
  {
    Parceiro: "UNIVERSO DOS DOCES COMÉRCIO DE EMBALAGENS EIRELI.-ME",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75043030",
    Bairro: "CENTRO",
    Rua: "QUINTINO BOCAIUVA",
    latitude: -16.3251969,
    longitude: -48.9594538,
  },
  {
    Parceiro: "SUPERMERCADO GIRASSOL",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74922100",
    Bairro: "JARDIM OLIMPICO",
    Rua: "SANTA RITA",
    latitude: -16.7472836,
    longitude: -49.2271649,
  },
  {
    Parceiro: "MEGA DOCES ATACADISTA EIRELI",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75025060",
    Bairro: "CENTRO",
    Rua: "RUI BARBOSA",
    latitude: -16.3252238,
    longitude: -48.9596352,
  },
  {
    Parceiro: "HIPERMERCADO CEU AZUL EIRELI",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75060470",
    Bairro: "JARDIM ALEXANDRINA",
    Rua: "WALDOMIRO CORREIA NETO",
    latitude: -16.2943591,
    longitude: -48.9637638,
  },
  {
    Parceiro: "CASA SAFRA SUPERMERCADOS LTDA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75120370",
    Bairro: "VILA GOIS",
    Rua: "DIVINO PAI ETERNO",
    latitude: -16.3405779,
    longitude: -48.959637,
  },
  {
    Parceiro: "LUAMIX DISTRIBUIDORA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74503110",
    Bairro: "SETOR CAMPINAS",
    Rua: "ANHANGUERA",
    latitude: -16.7202981,
    longitude: -49.2982889,
  },
  {
    Parceiro: "COMERCIAL VILA RICA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75091125",
    Bairro: "CONJUNTO HABITACIONAL FILOSTRO MACHADO CARNEIRO",
    Rua: "AYRTON SENNA",
    latitude: -16.3118478,
    longitude: -48.9038885,
  },
  {
    Parceiro: "SUPERMERCADO AKIRE EIRELI",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74465170",
    Bairro: "JARDIM NOVA ESPERANCA",
    Rua: "INDEPENDENCIA",
    latitude: -16.6469595,
    longitude: -49.3215655,
  },
  {
    Parceiro: "BIRUTÃO SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74356235",
    Bairro: "RESIDENCIAL SANTA FE",
    Rua: "RITA CAETANO",
    latitude: -16.7505087,
    longitude: -49.3751955,
  },
  {
    Parceiro: "HIPER VIP SUPERMERCADO LTDA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75083350",
    Bairro: "VILA SANTA ISABEL",
    Rua: "UNIVERSITARIA",
    latitude: -16.3023878,
    longitude: -48.9488104,
  },
  {
    Parceiro: "SUPER 10 SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74000001",
    Bairro: "CONJUNTO VERA CRUZ II",
    Rua: "ARGENTINA MONTEIRO",
    latitude: -16.6727427,
    longitude: -49.3913229,
  },
  {
    Parceiro: "SUPER LEGAL SUPERMECADOS",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74922685",
    Bairro: "SITIOS SANTA LUZIA",
    Rua: "VEREADOR GERALDO PADEIRO",
    latitude: -16.7503651,
    longitude: -49.2263015,
  },
  {
    Parceiro: "ALARCON COMERCIO DE ALIMENTOS EIRELI ME",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74921206",
    Bairro: "PARQUE TRINDADE",
    Rua: "BELA VISTA",
    latitude: -16.7145149,
    longitude: -49.260067,
  },
  {
    Parceiro: "PORTAL ATACAREJO LTDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74720220",
    Bairro: "VILA MARIA LUIZA",
    Rua: "ESTADOS UNIDOS",
    latitude: -16.6856979,
    longitude: -49.2201763,
  },
  {
    Parceiro: "ATENDE MAIS - JUNDIAÍ",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75115130",
    Bairro: "VILA INDUSTRIAL",
    Rua: "TENENTE JOSE R. JUNIOR",
    latitude: -16.338807,
    longitude: -48.94924289999999,
  },
  {
    Parceiro: "GOIABAO EMPORIO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74080295",
    Bairro: "SETOR SUL JAMIL MIGUEL",
    Rua: "87",
    latitude: -16.6901007,
    longitude: -49.2552528,
  },
  {
    Parceiro: "TIM TIM SUPERMERCADO LTDA ME",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75120673",
    Bairro: "VILA NOSSA SENHORA D'ABADIA",
    Rua: "ENGENHEIRO PORTELA",
    latitude: -16.3477885,
    longitude: -48.958229,
  },
  {
    Parceiro: "SUPERMERCADO PRÁTIKO - PEDRO MIRANDA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75262541",
    Bairro: "RESIDENCIAL PEDRO MIRANDA",
    Rua: "CASTRO ALVES",
    latitude: -16.73044,
    longitude: -49.087809,
  },
  {
    Parceiro: "SUPERMERCADO PRATIKO APARECIDA SETOR DOS AFONSOS",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74915290",
    Bairro: "SETOR DOS AFONSOS",
    Rua: "DOURADOS",
    latitude: -16.7379522,
    longitude: -49.26753249999999,
  },
  {
    Parceiro: "PAG LEVE ANAPOLIS",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75093135",
    Bairro: "PARQUE BRASILIA",
    Rua: "AYRTON SENNA",
    latitude: -16.320497,
    longitude: -48.9162309,
  },
  {
    Parceiro: "FICUS",
    Cidade: "Abadiania",
    Estado: "GO",
    CEP: "72940000",
    Bairro: "ZONA RURAL",
    Rua: "BR 060",
    latitude: -16.1905196,
    longitude: -48.6725587,
  },
  {
    Parceiro: "SENA SUPERMERCADOS",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75075120",
    Bairro: "BOA VISTA",
    Rua: "FRANCA",
    latitude: -16.3042264,
    longitude: -48.9391766,
  },
  {
    Parceiro: "PONTO FINAL",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74850230",
    Bairro: "VILA REDENCAO",
    Rua: "R 3",
    latitude: -16.725113,
    longitude: -49.242073,
  },
  {
    Parceiro: "SUPER SUPIMPA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74474048",
    Bairro: "JARDIM COLORADO",
    Rua: "DO CONTORNO",
    latitude: -16.6267189,
    longitude: -49.3331724,
  },
  {
    Parceiro: "PRATIKO SUPERMERCADO - LESTE UNIVERSITÁRIO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74603040",
    Bairro: "SETOR LESTE UNIVERSITARIO",
    Rua: "SEXTA AVENIDA",
    latitude: -16.6825541,
    longitude: -49.2411262,
  },
  {
    Parceiro: "SUPERMERCADO SOLUÇÃO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74913360",
    Bairro: "VILA ALZIRA",
    Rua: "MANGUEIRA",
    latitude: -16.7607015,
    longitude: -49.259042,
  },
  {
    Parceiro: "SUPERMERCADO MANANCIAL - AP. GOIÂNIA",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74927020",
    Bairro: "PARQUE TRINDADE II",
    Rua: "ANTIGA GO-352",
    latitude: -16.7482427,
    longitude: -49.2050999,
  },
  {
    Parceiro: "SUPERMERCADO PRÁTIKO - BOA VISTA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75258772",
    Bairro: "RESIDENCIAL BOA VISTA",
    Rua: "ANTONIO FLAVIO LIMA",
    latitude: -16.6764217,
    longitude: -49.1129599,
  },
  {
    Parceiro: "FLORESTA SUPERMERCADO - CENTRO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75024040",
    Bairro: "CENTRO",
    Rua: "GENERAL JOAQUIM INACIO",
    latitude: -16.3242107,
    longitude: -48.9575283,
  },
  {
    Parceiro: "FLORESTA SUPERMERCADOS - TIRADENTES",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75000001",
    Bairro: "CENTRO",
    Rua: "TIRADENTES",
    latitude: -16.3175159,
    longitude: -48.957952,
  },
  {
    Parceiro: "FLORESTA SUPERMERCADO - PARQUE BRASILIA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75093715",
    Bairro: "PARQUE BRASILIA 2A ETAPA",
    Rua: "COMERCIAL BLOCO D",
    latitude: -16.3254485,
    longitude: -48.9184827,
  },
  {
    Parceiro: "SUPERMERCADO PRÁTIKO - LESTE VILA NOVA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74635030",
    Bairro: "SETOR LESTE VILA NOVA",
    Rua: "CORONEL COSME",
    latitude: -16.6633086,
    longitude: -49.2397413,
  },
  {
    Parceiro: "COMERCIAL DIAS",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75095630",
    Bairro: "LOURDES",
    Rua: "4",
    latitude: -16.3312245,
    longitude: -48.9164429,
  },
  {
    Parceiro: "SUPERMERCADO VAREJÃO I LTDA",
    Cidade: "Valparaiso De Goias",
    Estado: "GO",
    CEP: "72871101",
    Bairro: "JARDIM CEU AZUL",
    Rua: "101",
    latitude: -16.0550242,
    longitude: -48.01042210000001,
  },
  {
    Parceiro: "SUPERMERCADO CERRADO LTDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74353520",
    Bairro: "JARDIM PRESIDENTE",
    Rua: "RIO VERDE",
    latitude: -16.6868912,
    longitude: -49.2647943,
  },
  {
    Parceiro: "SUPERMERCADO NEVES",
    Cidade: "Goianira",
    Estado: "GO",
    CEP: "75370000",
    Bairro: "SETOR LINDA VISTA",
    Rua: "24",
    latitude: -16.4965286,
    longitude: -49.43838909999999,
  },
  {
    Parceiro: "SUPERMERCADO VITORIA",
    Cidade: "Itanhanga ",
    Estado: "MT",
    CEP: "78579000",
    Bairro: "CENTRO",
    Rua: "CURITIBA",
    latitude: -12.2373119,
    longitude: -56.646486,
  },
  {
    Parceiro: "FORTE ATACADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74770030",
    Bairro: "VILA PEDROSO",
    Rua: "ANÁPOLIS",
    latitude: -16.6552762,
    longitude: -49.1776198,
  },
  {
    Parceiro: "HIPER MORITA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74605085",
    Bairro: "SETOR LESTE UNIVERSITARIO",
    Rua: "ANHANGUERA",
    latitude: -16.6767339,
    longitude: -49.2460984,
  },
  {
    Parceiro: "SUPERMERCADO GOIANÃO 3",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250493",
    Bairro: "CONJUNTO MORADA DO MORRO",
    Rua: "S 10",
    latitude: -16.6912671,
    longitude: -49.1173231,
  },
  {
    Parceiro: "EMBALAGENS SILVA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75261142",
    Bairro: "RESIDENCIAL ANUAR AUAD",
    Rua: "DOM EMANUEL",
    latitude: -16.6910444,
    longitude: -49.09489300000001,
  },
  {
    Parceiro: "SUPERMERCADO ALVORADA - SENADOR CANEDO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75255707",
    Bairro: "PARQUE ALVORADA",
    Rua: "PA 2",
    latitude: -16.6579379,
    longitude: -49.1659002,
  },
  {
    Parceiro: "MERCEARIA NOSSA SENHORA E DISTRIBUIDORA CHAPA QUENTE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74675380",
    Bairro: "JARDIM GUANABARA",
    Rua: "INDAIÁ",
    latitude: -16.6204575,
    longitude: -49.2170088,
  },
  {
    Parceiro: "BON ATACADISTA DE ALIMENTOS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74620430",
    Bairro: "FAZENDA RETIRO",
    Rua: "BR-153",
    latitude: -16.705965,
    longitude: -49.3242307,
  },
  {
    Parceiro: "EMPÓRIO TINK’S",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75105260",
    Bairro: "JARDIM ARCO VERDE",
    Rua: "ARCO VERDE",
    latitude: -16.370398,
    longitude: -48.9443277,
  },
  {
    Parceiro: "IPANEMA ALIMENTOS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74515030",
    Bairro: "SETOR CAMPINAS",
    Rua: "JOSÉ HERMANO",
    latitude: -16.67058,
    longitude: -49.2945231,
  },
  {
    Parceiro: "PEG PAG MENOS SANTA ISABEL",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75083420",
    Bairro: "VILA SANTA ISABEL",
    Rua: "4",
    latitude: -16.3080473,
    longitude: -48.94741740000001,
  },
  {
    Parceiro: "FORTE ATACAREJO - GOIÁ",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74485320",
    Bairro: "GOIA",
    Rua: "FELIPE CAMARAO",
    latitude: -16.6830526,
    longitude: -49.3280564,
  },
  {
    Parceiro: "SUPERMERCADO BOM DEMAIS",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75255861",
    Bairro: "RESIDENCIAL RIO ARAGUAIA",
    Rua: "DOURADOS",
    latitude: -16.6573037,
    longitude: -49.160685,
  },
  {
    Parceiro: "ARTESANATO DO PAO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75110810",
    Bairro: "JUNDIAI",
    Rua: "SÃO FRANCISCO DE ASSIS",
    latitude: -16.3305052,
    longitude: -48.9467438,
  },
  {
    Parceiro: "BONTELO SUPERMERCADO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75071650",
    Bairro: "PARQUE DOS PIRINEUS",
    Rua: "COLORADO",
    latitude: -16.27139887790127,
    longitude: -48.950490230683,
  },
  {
    Parceiro: "SANTIAGO EMBALAGENS",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75264260",
    Bairro: "TERRABELA CERRADO II",
    Rua: "PEDRO MIRANDA",
    latitude: -16.7001459,
    longitude: -49.0999618,
  },
  {
    Parceiro: "SUPERMERCADO OLIVEIRA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75106650",
    Bairro: "SETOR SUL II ETAPA",
    Rua: "PRINCIPAL",
    latitude: -16.3671487,
    longitude: -48.9473175,
  },
  {
    Parceiro: "EMPORIO ELDORADO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75115400",
    Bairro: "ELDORADO",
    Rua: "RODOVÂNIO RODOVALHO",
    latitude: -16.3464467,
    longitude: -48.946976,
  },
  {
    Parceiro: "SUPERMERCADO ATTACK",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74481340",
    Bairro: "JARDIM CURITIBA",
    Rua: "ORIENTE",
    latitude: -16.5964813,
    longitude: -49.3279635,
  },
  {
    Parceiro: "SUPERMERCADO VIEIRA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74710010",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "NEW YORK",
    latitude: -16.6794382,
    longitude: -49.2182748,
  },
  {
    Parceiro: "MELHOR ATACADISTA - SAMAMBAIA SUL",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72304101",
    Bairro: "SAMAMBAIA SUL (SAMAMBAIA)",
    Rua: "QN 122 CONJUNTO 1",
    latitude: -15.8705614,
    longitude: -48.0886966,
  },
  {
    Parceiro: "BRETAS B552 CD",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74675836",
    Bairro: "CHÁCARAS NOSSA SENHORA DA PIEDADE",
    Rua: "DE ACESSO A",
    latitude: -16.6868912,
    longitude: -49.2647943,
  },
  {
    Parceiro: "NOSSO SUPERMERCADO",
    Cidade: "Alexania",
    Estado: "GO",
    CEP: "72930000",
    Bairro: "CENTRO",
    Rua: "AVENIDA 15 DE NOVEMBRO",
    latitude: -16.0830441,
    longitude: -48.5073638,
  },
  {
    Parceiro: "TEMPERAR - TEMPEROS ARAGUAIA",
    Cidade: "Agua Boa",
    Estado: "MT",
    CEP: "78635000",
    Bairro: "SETOR NORTE",
    Rua: "RUA G-12",
    latitude: -14.0369153,
    longitude: -52.1586277,
  },
  {
    Parceiro: "SUPER CARDOSO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75101400",
    Bairro: "SETOR SUMMERVILLE",
    Rua: "SUMMERVILLE",
    latitude: -16.3392514,
    longitude: -48.9082263,
  },
  {
    Parceiro: "SUPER BIG",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75400004",
    Bairro: "VILA SANTA TEREZINHA",
    Rua: "ANTONIO MOREIRA",
    latitude: -16.373129,
    longitude: -49.4916776,
  },
  {
    Parceiro: "SIM ATACADO - JARDIM TROPICAL",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74946505",
    Bairro: "JARDIM TROPICAL",
    Rua: "LAGO DAS GARÇAS",
    latitude: -16.7675741,
    longitude: -49.35002129999999,
  },
  {
    Parceiro: "SUPERMERCADO RIO VERDE",
    Cidade: "Peixe",
    Estado: "TO",
    CEP: "77460000",
    Bairro: "ZONA RURAL",
    Rua: "ENTROCAMENTO DO JAU",
    latitude: -12.3446449,
    longitude: -48.6389295,
  },
  {
    Parceiro: "GLOBAL COM. DE CARNES EIRELI - EPP",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77020542",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ACSV SE 23",
    latitude: -10.1882974,
    longitude: -48.3152902,
  },
  {
    Parceiro: "SUPERMERCADO ALVORADA - ARAÇÚ",
    Cidade: "Araçu",
    Estado: "GO",
    CEP: "75410000",
    Bairro: "BAIRRO SOL NASCENTE",
    Rua: "RODOVIA  GO 222",
    latitude: -16.3620353,
    longitude: -49.6768209,
  },
  {
    Parceiro: "BEE MARKET",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77059028",
    Bairro: "LOTEAMENTO BERTAVILLE",
    Rua: "TRAJANO COÊLHO NETO",
    latitude: -10.2905151,
    longitude: -48.3181792,
  },
  {
    Parceiro: "SUPERMERCADO TOSTA - LOJA 2",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75803017",
    Bairro: "VILA FÁTIMA",
    Rua: "DEPUTADO HONORATO DE CARVALHO",
    latitude: -17.898644,
    longitude: -51.7360836,
  },
  {
    Parceiro: "CASA DO ROLAMENTOS",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75405170",
    Bairro: "PARQUE SAO JORGE",
    Rua: "BERNARDO SAYÃO",
    latitude: -16.373129,
    longitude: -49.4916776,
  },
  {
    Parceiro: "SUPERMERCADO BRASILEIRAO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75255710",
    Bairro: "PARQUE ALVORADA",
    Rua: "PA 1",
    latitude: -16.6605407,
    longitude: -49.1638637,
  },
  {
    Parceiro: "PONTO FRIOS",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75110815",
    Bairro: "JUNDIAI",
    Rua: "SÃO FRANCISCO DE ASSIS",
    latitude: -16.3395558,
    longitude: -48.9436627,
  },
  {
    Parceiro: "SUPERMERCADO SILVA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74450090",
    Bairro: "CAPUAVA",
    Rua: "CLAUDIO MANOEL DA COSTA",
    latitude: -16.6611973,
    longitude: -49.3232119,
  },
  {
    Parceiro: "SUPER ECONOMICO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74353170",
    Bairro: "JARDIM PRESIDENTE",
    Rua: "PRESIDENTE HERMES DA FONSECA",
    latitude: -16.7491824,
    longitude: -49.3267182,
  },
  {
    Parceiro: "SUPERMERCADO PAGUE MENOS",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74955500",
    Bairro: "VILA OLIVEIRA",
    Rua: "TURQUESA",
    latitude: -16.8069627,
    longitude: -49.2837218,
  },
  {
    Parceiro: "COMERCIAL ATACADAO",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75806796",
    Bairro: "CONJUNTO RESIDENCIAL NOSSA SENHORA DE FATIMA",
    Rua: "DAS OLIVEIRAS",
    latitude: -17.8560991,
    longitude: -51.7129881,
  },
  {
    Parceiro: "SUPERMERCADO LEVE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74610010",
    Bairro: "S LESTE UNIVERSITARI",
    Rua: "ANHANGUERA",
    latitude: -16.6703228,
    longitude: -49.2417234,
  },
  {
    Parceiro: "SUPERMERCADO PRIMICIAS",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74948190",
    Bairro: "JARDIM ALTO PARAISO",
    Rua: "NOSSA SENHORA DE GUADALUPE",
    latitude: -16.8115921,
    longitude: -49.3466033,
  },
  {
    Parceiro: "ROUTE 60",
    Cidade: "Abadiania",
    Estado: "GO",
    CEP: "72940000",
    Bairro: "ZONA RURAL",
    Rua: "BR 060 KM 58",
    latitude: -16.1854168,
    longitude: -48.6878124,
  },
  {
    Parceiro: "PEREIRA SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74493130",
    Bairro: "CONJ VERA CRUZ",
    Rua: "LEOPOLDO DE BULHOES",
    latitude: -16.6195353,
    longitude: -48.7436474,
  },
  {
    Parceiro: "SUPERCOUTO III",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74835620",
    Bairro: "PARQUE AMAZÔNIA",
    Rua: "JOSÉ RODRIGUES DE MORAIS NETO",
    latitude: -16.7306419,
    longitude: -49.2752996,
  },
  {
    Parceiro: "SUPERCOUTO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74921560",
    Bairro: "CONJUNTO RESIDENCIAL STORIL",
    Rua: "MIGUEL NACIF",
    latitude: -16.7650844,
    longitude: -49.2750903,
  },
  {
    Parceiro: "SUPERCOUTO NOVA ERA",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74916200",
    Bairro: "JARDIM NOVA ERA",
    Rua: "SAO JOAO",
    latitude: -16.7579087,
    longitude: -49.2775638,
  },
  {
    Parceiro: "PEIXAO SUPERMERCADO- EDINEI",
    Cidade: "Sandolandia",
    Estado: "TO",
    CEP: "77478000",
    Bairro: "CENTRO",
    Rua: "ULISSES GUIMARAES",
    latitude: -12.5393043,
    longitude: -49.92758320000001,
  },
  {
    Parceiro: "REQUEIJAO DA VOVO ONIZIA",
    Cidade: "Terezopolis De Goias",
    Estado: "GO",
    CEP: "75175000",
    Bairro: "SETOR SUL (GAMA)",
    Rua: "HUMBELINO FILHO",
    latitude: -16.4894938,
    longitude: -49.0985198,
  },
  {
    Parceiro: "SUPER MORELI",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74481220",
    Bairro: "JD CURITIBA",
    Rua: "JC45",
    latitude: -16.60289,
    longitude: -49.3361266,
  },
  {
    Parceiro: "BATISTA ATACADISTA",
    Cidade: "Goianira",
    Estado: "GO",
    CEP: "75370000",
    Bairro: "SETOR SUL (GAMA)",
    Rua: "JOSE BENTO DA COSTA",
    latitude: -16.5063749,
    longitude: -49.4223436,
  },
  {
    Parceiro: "RIO VERMELHO JUNDIAI",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75113025",
    Bairro: "JUNDIAI",
    Rua: "CONTORNO MATO GROSSO",
    latitude: -16.3337086,
    longitude: -48.9379168,
  },
  {
    Parceiro: "RIO VERMELHO MARACANA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75040320",
    Bairro: "BAIRRO MARACANA",
    Rua: "SENADOR RAMOS CAIADO",
    latitude: -16.3165995,
    longitude: -48.9531936,
  },
  {
    Parceiro: "RIO VERMELHO CATALAO",
    Cidade: "Catalao",
    Estado: "GO",
    CEP: "75713899",
    Bairro: "AREA RURAL DE CATALAO",
    Rua: "RURAL RODOVIA GO 210",
    latitude: -18.1664944,
    longitude: -47.9449344,
  },
  {
    Parceiro: "QUARTETTO ATACADO",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77023472",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ASR SE 95 (QUADRA 912 SUL) ALAMEDA 15",
    latitude: -10.237589,
    longitude: -48.3170006,
  },
  {
    Parceiro: "PANIFICADORA AVENIDA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75110750",
    Bairro: "JUNDIAI",
    Rua: "JOSE NETO PARANHOS",
    latitude: -16.3315509,
    longitude: -48.9345126,
  },
  {
    Parceiro: "BODEGA RESTAURANTE EIRELI",
    Cidade: "Terezopolis De Goias",
    Estado: "GO",
    CEP: "75175000",
    Bairro: "VILA HELIOPOLIS II",
    Rua: "HUMBELINO FILHO",
    latitude: -16.4834761,
    longitude: -49.0927941,
  },
  {
    Parceiro: "SUPERMERCADO SUPER ZE BUENO LTDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74230022",
    Bairro: "SETOR BUENO",
    Rua: "T 37",
    latitude: -16.7170986,
    longitude: -49.2657957,
  },
  {
    Parceiro: "SUPERMERCADO VIEIRA - LOJA 02",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75255060",
    Bairro: "CONJUNTO HABITACIONAL NOVA MORADA",
    Rua: "C 7",
    latitude: -16.6824798,
    longitude: -49.1821707,
  },
  {
    Parceiro: "RENDE MAIS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74356565",
    Bairro: "RES REAL CONQUISTA",
    Rua: "REAL CONQUISTA",
    latitude: -16.7817205,
    longitude: -49.384314,
  },
  {
    Parceiro: "SUPERMECADO M MACIEL",
    Cidade: "Trindade",
    Estado: "GO",
    CEP: "75382288",
    Bairro: "JARDIM SCALA",
    Rua: "TIRADENTES",
    latitude: -21.1102569,
    longitude: -44.1735339,
  },
  {
    Parceiro: "SUPER MAUGE SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74390770",
    Bairro: "VILA RIZZO",
    Rua: "VICTOR ESSELIN",
    latitude: -16.7232403,
    longitude: -49.373551,
  },
  {
    Parceiro: "GUANABARA ATACAREJO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74675380",
    Bairro: "JD GUANABARA",
    Rua: "INDAIÁ",
    latitude: -16.6206048,
    longitude: -49.2081617,
  },
  {
    Parceiro: "ROSE ATACADISTA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75259028",
    Bairro: "RESIDENCIAL BOA ESPERANCA",
    Rua: "BE 11",
    latitude: -16.6709424,
    longitude: -49.1062159,
  },
  {
    Parceiro: "SUPERMERCADO SANTA FE",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75255710",
    Bairro: "PARQUE ALVORADA",
    Rua: "PA 1",
    latitude: -16.6605407,
    longitude: -49.1638637,
  },
  {
    Parceiro: "ARAUJO'S BEBIDAS MINEMERCADO E MERCEARIA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74890275",
    Bairro: "PRQ ATHENEU",
    Rua: "13 UNIDADE 201",
    latitude: -16.7488104,
    longitude: -49.2022089,
  },
  {
    Parceiro: "SUPERMERCADO DO POVO- LOJA 1",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75053240",
    Bairro: "LOT. ADRIANA PARQUE",
    Rua: "G-12",
    latitude: -16.2777328,
    longitude: -48.9851379,
  },
  {
    Parceiro: "SUPERMERCADO DO POVO- LOJA 2",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75085630",
    Bairro: "PARQUE RESIDENCIAL DAS FLORES",
    Rua: "JOAO FLORENTINO",
    latitude: -16.2874412,
    longitude: -48.9309716,
  },
  {
    Parceiro: "ATACADAO BOM PRECO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74853030",
    Bairro: "JARDIM SANTO ANTONIO",
    Rua: "PB 1",
    latitude: -16.7291957,
    longitude: -49.2562665,
  },
  {
    Parceiro: "ULTRABOX - NOVA XINGUARA",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71250610",
    Bairro: "NOVA XINGUARA",
    Rua: "SCIA QUADRA 10 CONJUNTO 1",
    latitude: -7.104984099999999,
    longitude: -49.9459182,
  },
  {
    Parceiro: "SUPERMERCADO BOM PRECO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74971015",
    Bairro: "SETOR MARISTA SUL",
    Rua: "PARA",
    latitude: -16.8257971,
    longitude: -49.2792508,
  },
  {
    Parceiro: "SUPERMERCADO VALE DOS SONHOS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74684100",
    Bairro: "RESIDENCIAL VALE DOS SONHOS I",
    Rua: "ELIZENE DOS SANTOS SANTANA",
    latitude: -16.5969545,
    longitude: -49.2025879,
  },
  {
    Parceiro: "SIM ATACADO - GOIA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74486010",
    Bairro: "CONDOMÍNIO SANTA RITA",
    Rua: "LISIEUX",
    latitude: -16.6826138,
    longitude: -49.3304224,
  },
  {
    Parceiro: "SIM ATACADO - COLORADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74474048",
    Bairro: "JARDIM COLORADO",
    Rua: "DO CONTORNO",
    latitude: -16.6247012,
    longitude: -49.3362752,
  },
  {
    Parceiro: "SUPERMERCADO BETAO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74916200",
    Bairro: "JARDIM NOVA ERA",
    Rua: "SAO JOAO",
    latitude: -16.7496247,
    longitude: -49.2807131,
  },
  {
    Parceiro: "GIGANTE",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77023489",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ACSU SE 100 RUA NS B",
    latitude: -10.2451162,
    longitude: -48.3318334,
  },
  {
    Parceiro: "HIPER MOREIRA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74530020",
    Bairro: "SETOR COIMBRA",
    Rua: "PERIMETRAL",
    latitude: -16.6869482,
    longitude: -49.282177,
  },
  {
    Parceiro: "SUPERMAC ALIMENTOS EIRELI",
    Cidade: "Cezarina",
    Estado: "GO",
    CEP: "76195000",
    Bairro: "SETOR MARIA FRANCO II",
    Rua: "W-6",
    latitude: -16.9746252,
    longitude: -49.7734335,
  },
  {
    Parceiro: "SUPERMERCADO MORELI LTDA-ME.",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74475280",
    Bairro: "SETOR ESTRELA DALVA",
    Rua: "25 DE MARÇO",
    latitude: -16.6039578,
    longitude: -49.3375407,
  },
  {
    Parceiro: "SUPERMERCADO DUMONT",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74353210",
    Bairro: "JARDIM PRESIDENTE",
    Rua: "PRESIDENTE JEFFERSON",
    latitude: -16.7470017,
    longitude: -49.3237614,
  },
  {
    Parceiro: "RM COMERCIO DE ALIMENTOS LTDA",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75407861",
    Bairro: "SETOR ELDORADO",
    Rua: "ZILMAR RODRIGUES RABELO",
    latitude: -16.373129,
    longitude: -49.4916776,
  },
  {
    Parceiro: "DIEGÃO SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74720200",
    Bairro: "VILA MARIA LUIZA",
    Rua: "CORONEL ANDRELINO DE MORAIS",
    latitude: -16.6859081,
    longitude: -49.21986589999999,
  },
  {
    Parceiro: "DG SUPERMERCADO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75086022",
    Bairro: "RESIDENCIAL FLOR DO CERRADO",
    Rua: "FABIO DE ARAUJO",
    latitude: -16.2936399,
    longitude: -48.9237142,
  },
  {
    Parceiro: "GOIABÃO SUPERMERCADO - VERA CRUZ",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74493130",
    Bairro: "CONJUNTO VERA CRUZ II",
    Rua: "LEOPOLDO DE BULHOES",
    latitude: -16.658345,
    longitude: -49.38178200000001,
  },
  {
    Parceiro: "SUPERMERCADO ATENDE MAIS",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75123370",
    Bairro: "JARDIM GONCALVES",
    Rua: "SAO PEDRO",
    latitude: -16.3500102,
    longitude: -48.9580634,
  },
  {
    Parceiro: "ENOS VOVO ONIZIA",
    Cidade: "Goianapolis",
    Estado: "GO",
    CEP: "75170000",
    Bairro: "ZONA RURAL",
    Rua: "BR 153 - TEMPO DOS QUINTAIS",
    latitude: -16.4895254,
    longitude: -49.098506,
  },
  {
    Parceiro: "MELHOR ATACADISTA - RIACHO FUNDO I",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71825200",
    Bairro: "RIACHO FUNDO I",
    Rua: "QS 12",
    latitude: -15.889302,
    longitude: -48.0152216,
  },
  {
    Parceiro: "MELHOR ATACADISTA - VICENTE PIRES",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72005300",
    Bairro: "SETOR HABITACIONAL VICENTE PIRES",
    Rua: "EPTG CHACARA 53 D/E",
    latitude: -15.8153243,
    longitude: -48.0098739,
  },
  {
    Parceiro: "MELHOR ATACADISTA - FORMOSA",
    Cidade: "Formosa",
    Estado: "GO",
    CEP: "73813010",
    Bairro: "FORMOSINHA",
    Rua: "BRASILIA",
    latitude: -15.5580373,
    longitude: -47.3208572,
  },
  {
    Parceiro: "MELHOR ATACADISTA - RIACHO FUNDO II",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71880513",
    Bairro: "RIACHO FUNDO II",
    Rua: "QN 5A CONJUNTO 3",
    latitude: -15.9055806,
    longitude: -48.0504691,
  },
  {
    Parceiro: "TRIBOM SUPERMERCADO LTDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74369023",
    Bairro: "RESIDENCIAL CENTER VILLE",
    Rua: "VILLE",
    latitude: -16.7350295,
    longitude: -49.3481219,
  },
  {
    Parceiro: "BAI MINIMERCADOS LTDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74475076",
    Bairro: "SETOR PARQUE TREMENDÃO",
    Rua: "SPT 5",
    latitude: -16.6167996,
    longitude: -49.32747,
  },
  {
    Parceiro: "MERCADINHO ALTAMIX",
    Cidade: "Altamira",
    Estado: "PA",
    CEP: "68373092",
    Bairro: "JARDIM INDEPENDENTE I",
    Rua: "COQUEIRO",
    latitude: -3.2142139,
    longitude: -52.2311451,
  },
  {
    Parceiro: "RIO DAS PEDRAS - LOJA 2 - JARDIM CABRAL",
    Cidade: "Itaberai",
    Estado: "GO",
    CEP: "76630000",
    Bairro: "JARDIM CABRAL",
    Rua: "AUREO CABRAL",
    latitude: -16.0259826,
    longitude: -49.80973119999999,
  },
  {
    Parceiro: "RIO DAS PEDRAS - LOJA 3 - RES. ANA MARCELO",
    Cidade: "Itaberai",
    Estado: "GO",
    CEP: "76630000",
    Bairro: "RESIDENCIAL ANA MARCELO",
    Rua: "ANA PRIMO",
    latitude: -16.0097094,
    longitude: -49.7897324,
  },
  {
    Parceiro: "RIO DAS PEDRAS - LOJA 1 - CENTRO",
    Cidade: "Itaberai",
    Estado: "GO",
    CEP: "76630000",
    Bairro: "SETOR CENTRO",
    Rua: "AVENIDA DERVAL DE CASTRO",
    latitude: -16.0253826,
    longitude: -49.8008681,
  },
  {
    Parceiro: "SEMPRE MAIS SUPERMERCADO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75143377",
    Bairro: "VILA RESIDENCIAL PEDRO LUDOVICO",
    Rua: "PEDRO LUDOVICO",
    latitude: -16.3402728,
    longitude: -48.9679678,
  },
  {
    Parceiro: "MFR SUPERMERCADO EIRELI",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75045260",
    Bairro: "VILA SAO JOAO",
    Rua: "MAUA",
    latitude: -16.3180637,
    longitude: -48.9642435,
  },
  {
    Parceiro: "CONQUISTA SUPERMERCADO - MORADA DO SOL",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75909030",
    Bairro: "SETOR MORADA DO SOL",
    Rua: "QUINCA HONORIO",
    latitude: -17.7916464,
    longitude: -50.9454368,
  },
  {
    Parceiro: "CONQUISTA SUPERMERCADO - PARQUE BANDEIRANTE",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75905800",
    Bairro: "PARQUE BANDEIRANTE",
    Rua: "DEMOLÍNCIO DE CARVALHO",
    latitude: -17.7958338,
    longitude: -50.9187724,
  },
  {
    Parceiro: "CONQUISTA SUPERMERCADO - CENTRO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75901030",
    Bairro: "SETOR CENTRAL",
    Rua: "AUGUSTA BASTOS",
    latitude: -17.7922886,
    longitude: -50.9284699,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - CONJ. HABT. NOSSA SRA. APARECIDA",
    Cidade: "Santa Helena De Goiás",
    Estado: "GO",
    CEP: "75920000",
    Bairro: "CONJUNTO HABITACIONAL NOSSA SENHORA APARECIDA",
    Rua: "B",
    latitude: -17.8182858,
    longitude: -50.6039587,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - SANTO AGOSTINHO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75904610",
    Bairro: "SANTO AGOSTINHO",
    Rua: "MARIANA DA SILVEIRA LEÃO",
    latitude: -17.78162,
    longitude: -50.9133339,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - VILA BORGES PROLONGAMENTO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75902837",
    Bairro: "VILA BORGES PROLONGAMENTO",
    Rua: "ANTÔNIO SABINO",
    latitude: -17.7788159,
    longitude: -50.928142,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - PARQUE DOS BURITIS II",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75907390",
    Bairro: "PARQUE DOS BURITIS",
    Rua: "2",
    latitude: -17.8090804,
    longitude: -50.93739009999999,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - VILA BAYLÃO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75906466",
    Bairro: "VILA BAYLÃO",
    Rua: "TÉRCIO CAMPOS LEÃO",
    latitude: -17.8053937,
    longitude: -50.9265634,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - SETOR CENTRAL",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75901220",
    Bairro: "SETOR CENTRAL",
    Rua: "JOAQUIM VAZ DO NASCIMENTO",
    latitude: -17.796748,
    longitude: -50.93453299999999,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - NOVA VILA MARIA",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75900203",
    Bairro: "NOVA VILA MARIA",
    Rua: "PEDRO LUDOVICO TEIXEIRA",
    latitude: -17.786499,
    longitude: -50.9067894,
  },
  {
    Parceiro: "CITRO5 ATACAREJO - MARCELINO TEODORO GOMES",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75835059",
    Bairro: "SETOR MARCELINO TEODORO GOMES",
    Rua: "M 1",
    latitude: -17.5600557,
    longitude: -52.5492522,
  },
  {
    Parceiro: "CITRO5 ATACAREJO - CENTRO",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75830122",
    Bairro: "CENTRO",
    Rua: "QUINZE",
    latitude: -17.5600557,
    longitude: -52.5492522,
  },
  {
    Parceiro: "CITRO5 ATACAREJO - BOA VISTA",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75830002",
    Bairro: "MINEIRINHO",
    Rua: "VINTE",
    latitude: -17.5635333,
    longitude: -52.552764,
  },
  {
    Parceiro: "CITRO5 ATACAREJO - SETOR LEONTINO",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75834334",
    Bairro: "SETOR LEONTINO",
    Rua: "TOCANTINS",
    latitude: -17.5600557,
    longitude: -52.5492522,
  },
  {
    Parceiro: "CITRO5 ATACAREJO - DIVINO ESPÍRITO SANTO",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75831008",
    Bairro: "DIVINO ESPIRITO SANTO",
    Rua: "CRUZEIRO DO SUL",
    latitude: -17.5720608,
    longitude: -52.5639526,
  },
  {
    Parceiro: "SUPERMERCADO COMPRE BEM - MINEIROS",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75833130",
    Bairro: "SETOR AEROPORTO",
    Rua: "VINTE DOIS",
    latitude: -17.5527994,
    longitude: -52.55253769999999,
  },
  {
    Parceiro: "PAEZE - MORADA DO SOL",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75908780",
    Bairro: "SETOR MORADA DO SOL",
    Rua: "ELIZABETH CAMPOS",
    latitude: -17.7971725,
    longitude: -50.9474068,
  },
  {
    Parceiro: "PAEZE - RESIDENCIAL CANAÃ",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75909651",
    Bairro: "RESIDENCIAL CANAÃ",
    Rua: "CORONEL VAIANO",
    latitude: -17.782482,
    longitude: -50.94021919999999,
  },
  {
    Parceiro: "PAEZE - JARDIM AMÉRICA",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75902615",
    Bairro: "JARDIM AMERICA",
    Rua: "GERALDO ANDRADE",
    latitude: -17.7867526,
    longitude: -50.9219232,
  },
  {
    Parceiro: "SUPERMERCADO JATAÍ",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75800145",
    Bairro: "VILA SANTA MARIA",
    Rua: "RIACHUELO",
    latitude: -17.8848846,
    longitude: -51.7267181,
  },
  {
    Parceiro: "EMPÓRIO MM - MORADA DO SOL",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75908740",
    Bairro: "SETOR MORADA DO SOL",
    Rua: "JOSÉ WALTER",
    latitude: -17.7945247,
    longitude: -50.9432945,
  },
  {
    Parceiro: "EMPÓRIO MM - CENTRO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75901060",
    Bairro: "SETOR CENTRAL",
    Rua: "ABEL PEREIRA DE CASTRO",
    latitude: -17.7945684,
    longitude: -50.9261911,
  },
  {
    Parceiro: "SUL SUPERMERCADO LTDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74354390",
    Bairro: "SETOR CRISTINA",
    Rua: "BARTOLOMEU BUENO",
    latitude: -16.7620623,
    longitude: -49.3513203,
  },
  {
    Parceiro: "SUPER ADEGA - JARDIM BOTANICO",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71681730",
    Bairro: "SETOR HABITACIONAL JARDIM BOTANICO",
    Rua: "BELA VISTA LOTES L, N E M PARTE 02",
    latitude: -15.8897414,
    longitude: -47.7975751,
  },
  {
    Parceiro: "SUPER ADEGA - TAGUATINGA NORTE",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72155506",
    Bairro: "TAGUATINGA NORTE (TAGUATINGA)",
    Rua: "QNL 2 AREA ESPECIAL 3 PARTE",
    latitude: -15.8341501,
    longitude: -48.0736227,
  },
  {
    Parceiro: "SUPER ADEGA - TAGUATINGA SUL",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72035520",
    Bairro: "TAGUATINGA SUL (TAGUATINGA)",
    Rua: "CSG 20 LOTE 02 PARTE",
    latitude: -15.8740597,
    longitude: -48.031111,
  },
  {
    Parceiro: "TATICO - VICENTE PIRES",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72007790",
    Bairro: "SETOR HABITACIONAL VICENTE PIRES",
    Rua: "12 CHACARA 315",
    latitude: -15.7947601,
    longitude: -48.0489822,
  },
  {
    Parceiro: "TATICO - SANTA MARIA",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72500103",
    Bairro: "SANTA MARIA",
    Rua: "AC 200 CONJUNTO C",
    latitude: -16.0460179,
    longitude: -48.0319507,
  },
  {
    Parceiro: "TATICO - ASA NORTE",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70702060",
    Bairro: "ASA NORTE",
    Rua: "SHN QUADRA 2 BLOCO F",
    latitude: -15.7893342,
    longitude: -47.8879347,
  },
  {
    Parceiro: "TATICO - SAMAMBAIA NORTE",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72341001",
    Bairro: "SAMAMBAIA NORTE (SAMAMBAIA)",
    Rua: "QUADRA 201 CONJUNTO 1",
    latitude: -15.8768959,
    longitude: -48.0885104,
  },
  {
    Parceiro: "TATICO - SAMAMBAIA SUL",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72308002",
    Bairro: "SAMAMBAIA SUL (SAMAMBAIA)",
    Rua: "QN 312 CONJUNTO 2 LOTE",
    latitude: -15.8733988,
    longitude: -48.0704187,
  },
  {
    Parceiro: "TATICO - RECANTO DAS EMAS",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72601214",
    Bairro: "RECANTO DAS EMAS",
    Rua: "QUADRA 106",
    latitude: -15.90685,
    longitude: -48.08293,
  },
  {
    Parceiro: "TATICO - CEILANDIA SUL",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72220280",
    Bairro: "CEILANDIA SUL (CEILANDIA)",
    Rua: "QNN 28",
    latitude: -15.83778,
    longitude: -48.10329,
  },
  {
    Parceiro: "TATICO - LUZIANIA - VERA CRUZ",
    Cidade: "Luziania",
    Estado: "GO",
    CEP: "72854718",
    Bairro: "VERA CRUZ",
    Rua: "QUADRA 6",
    latitude: -16.2506453,
    longitude: -47.9261172,
  },
  {
    Parceiro: "TATICO - AGUAS LINDAS 2",
    Cidade: "Águas Lindas De Goiás",
    Estado: "GO",
    CEP: "72920004",
    Bairro: "JARDIM DA BARRAGEM III",
    Rua: "QUADRA GLEBA",
    latitude: -15.7325238,
    longitude: -48.2916263,
  },
  {
    Parceiro: "TATICO - AGUAS LINDAS",
    Cidade: "Águas Lindas De Goiás",
    Estado: "GO",
    CEP: "72910052",
    Bairro: "PARQUE DA BARRAGEM SETOR 08",
    Rua: "45 CONJUNTO B",
    latitude: -15.7543648,
    longitude: -48.2741962,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - SETOR DAS MANSOES",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75804363",
    Bairro: "SETOR DAS MANSOES",
    Rua: "VERIANO DE OLIVEIRA LIMA",
    latitude: -17.8840694,
    longitude: -51.73374930000001,
  },
  {
    Parceiro: "SUPERMERCADO COMPRE BEM - INHUMAS",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75400000",
    Bairro: "VILA LUCIMAR",
    Rua: "BERNARDO SAYÃO",
    latitude: -16.373129,
    longitude: -49.4916776,
  },
  {
    Parceiro: "RANCHÃO COMIVA",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75830000",
    Bairro: "PECUARIA",
    Rua: "CINCO",
    latitude: -17.56927,
    longitude: -52.555751,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - VILA RENOVAÇÃO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75906010",
    Bairro: "VILA RENOVACAO",
    Rua: "SUL GOIANA",
    latitude: -17.8001908,
    longitude: -50.9072203,
  },
  {
    Parceiro: "MAR ABERTO",
    Cidade: "Mata De Sao Joao",
    Estado: "BA",
    CEP: "48280000",
    Bairro: "MONTE LIBANO - SEDE",
    Rua: "MONTE LIBANO",
    latitude: -12.5196836,
    longitude: -38.3146882,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77023348",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ARSE 91 ALAMEDA 5",
    latitude: -10.2459177,
    longitude: -48.325277,
  },
  {
    Parceiro: "FRIGOMAIS LTDA",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77023537",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ARSE 102 AVENIDA NS 4",
    latitude: -10.2480753,
    longitude: -48.3240996,
  },
  {
    Parceiro: "COMERCIAL REIS - MORRINHOS - CENTRO",
    Cidade: "Morrinhos",
    Estado: "GO",
    CEP: "75650000",
    Bairro: "SETOR CENTRO",
    Rua: "DOUTOR GUMERCINDO OTERO",
    latitude: -17.7351436,
    longitude: -49.11180909999999,
  },
  {
    Parceiro: "COMERCIAL REIS - RIO VERDE - JARDIM PRESIDENTE",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75908420",
    Bairro: "JARDIM PRESIDENTE",
    Rua: "PRESIDENTE VARGAS",
    latitude: -17.79914,
    longitude: -50.9406254,
  },
  {
    Parceiro: "COMERCIAL REIS - CALDAS NOVAS - CONDOMINIO DAS NAÇÕES",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75690000",
    Bairro: "CONDOMINIO DAS NACOES",
    Rua: "DAS NACOES",
    latitude: -17.7445029,
    longitude: -48.6251087,
  },
  {
    Parceiro: "CAMPEÃO SUPERMERCADO - VILA SANTA MARIA",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75800093",
    Bairro: "VILA SANTA MARIA",
    Rua: "VERIANO DE OLIVEIRA LIMA",
    latitude: -17.8840694,
    longitude: -51.73374930000001,
  },
  {
    Parceiro: "RESTAURANTE O ESTRADEIRO LTDA",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75800001",
    Bairro: "SETOR INDUSTRIAL (GAMA)",
    Rua: "BR 364 COM BR 060",
    latitude: -17.9144033,
    longitude: -51.7671072,
  },
  {
    Parceiro: "SUPERMERCADO FLAMBOYANT",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75906880",
    Bairro: "RESIDENCIAL GAMELEIRA",
    Rua: "FLAMBOYANT",
    latitude: -17.8148234,
    longitude: -50.9094631,
  },
  {
    Parceiro: "SUPERMERCADO MINI BOX - NORTE ALAMEDA 19",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77001270",
    Bairro: "PLANO DIRETOR NORTE",
    Rua: "ARNO 31 ALAMEDA 19",
    latitude: -10.1497064,
    longitude: -48.3127235,
  },
  {
    Parceiro: "SUPERMERCADO MINI BOX - NORTE ALAMEDA 17",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77006398",
    Bairro: "PLANO DIRETOR NORTE",
    Rua: "ARNE 51 ALAMEDA 17",
    latitude: -10.1658412,
    longitude: -48.3216761,
  },
  {
    Parceiro: "SUPERMERCADO PONTUAL 2",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75910039",
    Bairro: "RESIDENCIAL VENEZA",
    Rua: "WOLNEY DA COSTA MARTINS",
    latitude: -17.7734232,
    longitude: -50.90726309999999,
  },
  {
    Parceiro: "SUPERMERCADO DAMASCO",
    Cidade: "Santa Helena De Goiás",
    Estado: "GO",
    CEP: "75920000",
    Bairro: "NOSSA SENHORA APARECIDA",
    Rua: "BR 050, KM 66,3",
    latitude: -22.8469289,
    longitude: -45.2316803,
  },
  {
    Parceiro: "SUPER ADEGA - SETOR LESTE (GAMA)",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72444220",
    Bairro: "SETOR LESTE (GAMA)",
    Rua: "ESPECIAL DE INDÚSTRIA 2 AMBEV",
    latitude: -16.0234586,
    longitude: -48.0541712,
  },
  {
    Parceiro: "SUPERMERCADO LEO - PQ. SANTA RITA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74393480",
    Bairro: "PARQUE SANTA RITA",
    Rua: "SR 7",
    latitude: -16.7219721,
    longitude: -49.3692469,
  },
  {
    Parceiro: "SUPERMERCADO LEO - MAX SUPERMERCADO - VILA MUTIRÃO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74475425",
    Bairro: "VILA MUTIRÃO II",
    Rua: "DO POVO",
    latitude: -16.6141907,
    longitude: -49.3170247,
  },
  {
    Parceiro: "SUPERMERCADO LEO - MAX SUPERMERCADO - RES. RIO VERDE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74370875",
    Bairro: "RESIDENCIAL RIO VERDE",
    Rua: "SERINGUEIRAS",
    latitude: -16.7379568,
    longitude: -49.3691226,
  },
  {
    Parceiro: "SUPERMERCADO LEO - SUPERMERCADO MAX - AP. GOIÂNIA",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74959276",
    Bairro: "INDEPENDÊNCIA - 1º COMPLEMENTO SETOR DAS MANSÕES",
    Rua: "BENEDITO SILVESTRE DE TOLEDO",
    latitude: -16.8209007,
    longitude: -49.3081306,
  },
  {
    Parceiro: "SUPERMERCADO CAMPELO",
    Cidade: "Araguaína",
    Estado: "TO",
    CEP: "77820596",
    Bairro: "LOTEAMENTO JARDIM BOA SORTE",
    Rua: "TO 222 APM 16",
    latitude: -7.2022508,
    longitude: -48.2174016,
  },
  {
    Parceiro: "SUPERMERCADO MAIS VOCE II",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: "75403746",
    Bairro: "RESIDENCIAL ANA BRANDAO",
    Rua: "BERNARDO SAYÃO",
    latitude: -16.3807657,
    longitude: -49.48630310000001,
  },
  {
    Parceiro: "SUPERMERCADO SÃO JUDAS TADEU",
    Cidade: "Colinas Do Tocantins",
    Estado: "TO",
    CEP: "77760000",
    Bairro: "CENTRO",
    Rua: "BERNARDO SAYÃO",
    latitude: -8.058299999999999,
    longitude: -48.4765549,
  },
  {
    Parceiro: "SUPERMERCADO LUCAS - QUIRINÓPOLIS - CHÁCARAS",
    Cidade: "Quirinópolis",
    Estado: "GO",
    CEP: "75860000",
    Bairro: "CHACARAS",
    Rua: "LEOCADIO DE SOUZA REIS",
    latitude: -18.447542,
    longitude: -50.4368387,
  },
  {
    Parceiro: "SUPERMERCADO LUCAS - RIO VERDE - PAUZANES",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75904200",
    Bairro: "SETOR PAUZANES",
    Rua: "SERGIPE",
    latitude: -17.7726151,
    longitude: -50.926323,
  },
  {
    Parceiro: "SUPERMERCADO LUCAS - QUIRINÓPOLIS - CENTRO",
    Cidade: "Quirinópolis",
    Estado: "GO",
    CEP: "75860000",
    Bairro: "CENTRO",
    Rua: "JOSE JOAQUIM CABRAL",
    latitude: -18.4415987,
    longitude: -50.4535056,
  },
  {
    Parceiro: "EMPÓRIO MM - SOLAR DO AGRESTE",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75907256",
    Bairro: "PARQUE SOLAR DO AGRESTE A",
    Rua: "11",
    latitude: -17.806786,
    longitude: -50.935809,
  },
  {
    Parceiro: "SUPERMERCADO PARAÍSO",
    Cidade: "Chapadão Do Céu",
    Estado: "GO",
    CEP: "75828000",
    Bairro: "CENTRO",
    Rua: "GUAPEVA OESTE ESQUINA C/ AV NETUNO SUL",
    latitude: -18.3948368,
    longitude: -52.6680325,
  },
  {
    Parceiro: "SUPER REI",
    Cidade: "Chapadão Do Céu",
    Estado: "GO",
    CEP: "75828000",
    Bairro: "CENTRO",
    Rua: "PEGASO SUL",
    latitude: -18.3930561,
    longitude: -52.6664492,
  },
  {
    Parceiro: "D'GUST TEMPEROS",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75903380",
    Bairro: "JARDIM GOIAS",
    Rua: "OSÓRIO COELHO DE MORAES",
    latitude: -17.7889601,
    longitude: -50.917205,
  },
  {
    Parceiro: "SUPERMERCADO DIA A DIA - JATAÍ",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75800257",
    Bairro: "SETOR IRACEMA",
    Rua: "MIRANDA DE CARVALHO",
    latitude: -17.8801129,
    longitude: -51.7135492,
  },
  {
    Parceiro: "EMPÓRIO CASARÃO",
    Cidade: "Cezarina",
    Estado: "GO",
    CEP: "76195000",
    Bairro: "< SEM  BAIRRO >",
    Rua: "<SEM ENDERECO>",
    latitude: -17.0763731,
    longitude: -49.8202007,
  },
  {
    Parceiro: "SETE PORTAS SUPERMERCADOS - MAR ABERTO",
    Cidade: "Salvador",
    Estado: "BA",
    CEP: "40300756",
    Bairro: "BARBALHO",
    Rua: "CÔNEGO PEREIRA",
    latitude: -12.9684898,
    longitude: -38.4955266,
  },
  {
    Parceiro: "NOVO VAREJO - RUA NOVA ABRANTES",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42827678",
    Bairro: "VILA DE ABRANTES (ABRANTES)",
    Rua: "ANTÔNIO ELIAS DUARTE",
    latitude: -12.8392002,
    longitude: -38.2656261,
  },
  {
    Parceiro: "NOVO VAREJO - AV. TIRADENTES",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42827762",
    Bairro: "VILA DE ABRANTES (ABRANTES)",
    Rua: "TIRADENTES",
    latitude: -12.7001932,
    longitude: -38.325345,
  },
  {
    Parceiro: "NOVO VAREJO - RUA DO FLAMINGO",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42828576",
    Bairro: "JAUÁ (ABRANTES)",
    Rua: "DIRETA DE JAUÁ",
    latitude: -12.8234066,
    longitude: -38.234841,
  },
  {
    Parceiro: "MERCEARIA EXTRA",
    Cidade: "Santa Rita Do Araguaia",
    Estado: "GO",
    CEP: "75840000",
    Bairro: "VILA NOVA",
    Rua: "EPIFANIO MOREIRA SANTOS",
    latitude: -17.3245772,
    longitude: -53.2009597,
  },
  {
    Parceiro: "MELHOR ATACADISTA - GUARA II",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71070440",
    Bairro: "GUARÁ II",
    Rua: "QE 44",
    latitude: -15.8484171,
    longitude: -47.9648462,
  },
  {
    Parceiro: "MELHOR ATACADISTA - ÁGUAS CLARAS - AREAL",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71974000",
    Bairro: "AREAL (AGUAS CLARAS)",
    Rua: "ÁGUAS CLARAS QS 08",
    latitude: -15.8637675,
    longitude: -48.02450349999999,
  },
  {
    Parceiro: "MELHOR ATACADISTA - VALPARAÍSO DE GOIÁS",
    Cidade: "Valparaiso De Goias",
    Estado: "GO",
    CEP: "72879274",
    Bairro: "YPIRANGA",
    Rua: "MARAJÓ",
    latitude: -16.0550738,
    longitude: -47.9669597,
  },
  {
    Parceiro: "CDP SUPERMERCADOS - MAR ABERTO - ARMAÇÃO",
    Cidade: "Salvador",
    Estado: "BA",
    CEP: "41750160",
    Bairro: "ARMAÇÃO",
    Rua: "PROFESSOR MANOEL RIBEIRO",
    latitude: -12.9827729,
    longitude: -38.4394256,
  },
  {
    Parceiro: "CDP SUPERMERCADOS - MAR ABERTO - ACUPE DE BROTAS",
    Cidade: "Salvador",
    Estado: "BA",
    CEP: "40290110",
    Bairro: "ACUPE DE BROTAS",
    Rua: "VALE DO OGUNJÁ",
    latitude: -12.9939756,
    longitude: -38.4958378,
  },
  {
    Parceiro: "MAIS SUPERMERCADO",
    Cidade: "Lauro De Freitas",
    Estado: "BA",
    CEP: "42717000",
    Bairro: "VIDA NOVA",
    Rua: "SANTO AMARO DE IPITANGA",
    latitude: -12.8721749,
    longitude: -38.3388755,
  },
  {
    Parceiro: "MELHOR DA PRAÇA",
    Cidade: "Salvador",
    Estado: "BA",
    CEP: "41710200",
    Bairro: "BOCA DO RIO",
    Rua: "HÉLIO MACHADO",
    latitude: -12.9795973,
    longitude: -38.4328661,
  },
  {
    Parceiro: "COMERCIAL REIS - SANTA HELENA DE GOIÁS",
    Cidade: "Santa Helena De Goiás",
    Estado: "GO",
    CEP: "75920000",
    Bairro: "PEDROLINA",
    Rua: "FRANCISCO LOURENCO GOULART",
    latitude: -17.8108166,
    longitude: -50.5869397,
  },
  {
    Parceiro: "SÃO JOSÉ SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74494650",
    Bairro: "JARDIM SÃO JOSÉ",
    Rua: "GERCINA BORGES TEIXEIRA",
    latitude: -16.691337,
    longitude: -49.390339,
  },
  {
    Parceiro: "FEIRÃO MIX SUPERMERCADO",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75837613",
    Bairro: "PARQUE DOS JATOBAS",
    Rua: "F",
    latitude: -17.5759184,
    longitude: -52.543965,
  },
  {
    Parceiro: "REAL UTILIDADES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74425010",
    Bairro: "CIDADE JARDIM",
    Rua: "PIO XII",
    latitude: -16.6793017,
    longitude: -49.3078077,
  },
  {
    Parceiro: "SUPERMERCADO FAICALVILLE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74360030",
    Bairro: "SETOR FAIÇALVILLE",
    Rua: "CASTRO ALVES",
    latitude: -16.7334987,
    longitude: -49.3150364,
  },
  {
    Parceiro: "SUPERMERCADO FONSECA",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42828576",
    Bairro: "JAUÁ (ABRANTES)",
    Rua: "DIRETA DE JAUÁ",
    latitude: -12.8234066,
    longitude: -38.234841,
  },
  {
    Parceiro: "SUPERMERCADO ABC - CENTRO",
    Cidade: "Itaruma",
    Estado: "GO",
    CEP: "75810000",
    Bairro: "CENTRO",
    Rua: "SAO SEBASTIAO",
    latitude: -18.7636123,
    longitude: -51.3445488,
  },
  {
    Parceiro: "MEDEIROS SUPERMERCADO - JARDIM AGUIAR",
    Cidade: "Cacu",
    Estado: "GO",
    CEP: "75813000",
    Bairro: "JARDIM AGUIAR",
    Rua: "NECA BORGES",
    latitude: -18.5623781,
    longitude: -51.1370486,
  },
  {
    Parceiro: "SUPERMERCADO ABC - SÃO SIMÃO",
    Cidade: "Sao Simao",
    Estado: "GO",
    CEP: "75890000",
    Bairro: "VILA CEMIG II",
    Rua: "RIO DE JANEIRO",
    latitude: -18.9965233,
    longitude: -50.5480764,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO 2",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77024054",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ACSV SE 112",
    latitude: -10.1882974,
    longitude: -48.3152902,
  },
  {
    Parceiro: "DISTRIBUIDORA K NEVES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74305490",
    Bairro: "SETOR SUDOESTE",
    Rua: "C70",
    latitude: -16.6983371,
    longitude: -49.3015848,
  },
  {
    Parceiro: "SUPERMERCADO ANESTOR",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75073580",
    Bairro: "LOTEAMENTO RESIDENCIAL AMÉRICA",
    Rua: "RA-011",
    latitude: -16.2767362,
    longitude: -48.9387672,
  },
  {
    Parceiro: "NOSSA KAZA F10 - VALPARAÍSO - PQ. ESPLANADA III",
    Cidade: "Valparaiso De Goias",
    Estado: "GO",
    CEP: "72876312",
    Bairro: "PARQUE ESPLANADA III",
    Rua: "304",
    latitude: -16.0792903,
    longitude: -47.9795175,
  },
  {
    Parceiro: "NOSSA KAZA F01 - LUZIANIA - PARQUE JK",
    Cidade: "Luziania",
    Estado: "GO",
    CEP: "72815560",
    Bairro: "PARQUE JK",
    Rua: "NEYLO ROLIM",
    latitude: -16.2135626,
    longitude: -47.9301076,
  },
  {
    Parceiro: "NOSSA KAZA F02 - LUZIANIA - MACHADO DE ARAÚJO",
    Cidade: "Luziania",
    Estado: "GO",
    CEP: "72810180",
    Bairro: "DIOGO MACHADO DE ARAÚJO",
    Rua: "MODESTO MACHADO",
    latitude: -16.2525918,
    longitude: -47.9612689,
  },
  {
    Parceiro: "NOSSA KAZA F03 - LUZIANIA - SETOR FUMAL",
    Cidade: "Luziania",
    Estado: "GO",
    CEP: "72801520",
    Bairro: "SETOR FUMAL",
    Rua: "ELIZENE DOS SANTOS SANTANA",
    latitude: -16.2630628,
    longitude: -47.9595981,
  },
  {
    Parceiro: "NOSSA KAZA F05 - LUZIANIA - CENTRO",
    Cidade: "Luziania",
    Estado: "GO",
    CEP: "72800200",
    Bairro: "CENTRO",
    Rua: "CORONEL ANTONIO CARNEIRO",
    latitude: -16.2535631,
    longitude: -47.9506862,
  },
  {
    Parceiro: "NOSSA KAZA F06 - VALPARAÍSO - CHÁCARAS ANHANGUERA",
    Cidade: "Valparaiso De Goias",
    Estado: "GO",
    CEP: "72871593",
    Bairro: "SETOR DE CHACARAS ANHANGUERA B",
    Rua: "QUADRA 38",
    latitude: -16.0664761,
    longitude: -48.0110969,
  },
  {
    Parceiro: "NOSSA KAZA F07 - LUZIANIA - PQ. ALVORADA I",
    Cidade: "Luziania",
    Estado: "GO",
    CEP: "72836330",
    Bairro: "PARQUE ALVORADA I",
    Rua: "DOUTOR NEYLO ROLIM",
    latitude: -16.2135147,
    longitude: -47.9301804,
  },
  {
    Parceiro: "NOSSA KAZA F08 - CIDADE OCIDENTAL - PQ. ARAGUARI",
    Cidade: "Cidade Ocidental",
    Estado: "GO",
    CEP: "72885370",
    Bairro: "PARQUE ARAGUARI",
    Rua: "QUADRA 18",
    latitude: -16.104526,
    longitude: -47.9539844,
  },
  {
    Parceiro: "NOSSA KAZA F09 - VALPARAÍSO - CHÁCARAS YPIRANGA A",
    Cidade: "Valparaiso De Goias",
    Estado: "GO",
    CEP: "72879050",
    Bairro: "CHACARAS YPIRANGA A",
    Rua: "901 SUL ALAMEDA 15",
    latitude: -16.0601718,
    longitude: -47.9700737,
  },
  {
    Parceiro: "NOSSA KAZA F04 - LUZIANIA - NORTE MARAVILHA",
    Cidade: "Luziania",
    Estado: "GO",
    CEP: "72812610",
    Bairro: "SETOR NORTE MARAVILHA",
    Rua: "DOUTOR SEBASTIAO CARNEIRO DE MENDONCA",
    latitude: -16.2407149,
    longitude: -47.9490518,
  },
  {
    Parceiro: "NOSSA KAZA F11 - BRASÍLIA - TAGUATINGA",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72125525",
    Bairro: "TAGUATINGA NORTE (TAGUATINGA)",
    Rua: "CNF 2",
    latitude: -15.8148151,
    longitude: -48.0672576,
  },
  {
    Parceiro: "ARAGUAIA SUPERMERCADO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75255879",
    Bairro: "RESIDENCIAL RIO ARAGUAIA",
    Rua: "ARUANA",
    latitude: -16.6578632,
    longitude: -49.1598324,
  },
  {
    Parceiro: "CYCLONE EMBALAGENS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74705020",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "CAMPOS ELISIOS",
    latitude: -16.6687873,
    longitude: -49.2203434,
  },
  {
    Parceiro: "EMPÓRIO ALVES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74770030",
    Bairro: "VILA PEDROSO",
    Rua: "ANÁPOLIS",
    latitude: -16.6601735,
    longitude: -49.1852871,
  },
  {
    Parceiro: "EMPÓRIO INDAIA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74680770",
    Bairro: "JARDIM GUANABARA III",
    Rua: "GB8",
    latitude: -16.6119926,
    longitude: -49.21301330000001,
  },
  {
    Parceiro: "EMPÓRIO MORITA L1",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74643020",
    Bairro: "SETOR LESTE VILA NOVA",
    Rua: "DO BOTAFOGO",
    latitude: -16.6712787,
    longitude: -49.247504,
  },
  {
    Parceiro: "STORE MATOS",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75261483",
    Bairro: "VILA BONSUCESSO",
    Rua: "PERIMETRAL",
    latitude: -16.7116904,
    longitude: -49.09146339999999,
  },
  {
    Parceiro: "SUPER FAZENDAO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75257608",
    Bairro: "RESIDENCIAL FLOR DO IPE I",
    Rua: "ADRIANO AUAD",
    latitude: -16.7001459,
    longitude: -49.0999618,
  },
  {
    Parceiro: "MEGA EMPORIO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250157",
    Bairro: "RESIDENCIAL JARDIM CANEDO",
    Rua: "DOUTOR JOSE CARNEIRO",
    latitude: -16.6945858,
    longitude: -49.1080844,
  },
  {
    Parceiro: "MERCEARIA CANEDO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250655",
    Bairro: "RESIDENCIAL JARDIM CANEDO III",
    Rua: "JC 1",
    latitude: -16.6998873,
    longitude: -49.1187037,
  },
  {
    Parceiro: "MERCEARIA CARDOSO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250000",
    Bairro: "JARDIM DAS OLIVEIRAS I",
    Rua: "JM-20",
    latitude: -16.6515044,
    longitude: -49.1559911,
  },
  {
    Parceiro: "MERCEARIA G13",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74555250",
    Bairro: "VILA SANTA HELENA",
    Rua: "13 MYTLE STREET",
    latitude: -16.6575905,
    longitude: -49.2828756,
  },
  {
    Parceiro: "MERCEARIA ROCHA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250732",
    Bairro: "RESIDENCIAL JARDIM CANEDO III",
    Rua: "JC 40",
    latitude: -16.7031802,
    longitude: -49.1221618,
  },
  {
    Parceiro: "MERCEARIA SANTO ANTÔNIO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75256272",
    Bairro: "JARDIM DAS OLIVEIRAS II",
    Rua: "JO 47",
    latitude: -16.6548483,
    longitude: -49.14974000000001,
  },
  {
    Parceiro: "PORMAR SUPERMERCADO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75251434",
    Bairro: "SETOR CENTRAL 2",
    Rua: "DO GLOBO",
    latitude: -16.7070399,
    longitude: -49.0944695,
  },
  {
    Parceiro: "POTENCIAL ATACADISTA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74713440",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "NEW YORK",
    latitude: -16.6757973,
    longitude: -49.2193122,
  },
  {
    Parceiro: "QUALY FRIOS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74705120",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "LA PAZ",
    latitude: -16.6676554,
    longitude: -49.2186146,
  },
  {
    Parceiro: "RECANTO SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74635170",
    Bairro: "VILA VIANA",
    Rua: "402 SUL AVENIDA NS B",
    latitude: -16.6578404,
    longitude: -49.2382916,
  },
  {
    Parceiro: "RIO VERMELHO DISTRIBUIÇÃO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75105020",
    Bairro: "JARDIM ELDORADO",
    Rua: "BR-153",
    latitude: -16.3568762,
    longitude: -48.9318698,
  },
  {
    Parceiro: "SMART TEND TUDO AMENDOEIRAS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74780500",
    Bairro: "PARQUE DAS AMENDOEIRAS",
    Rua: "PAULO ALVES DA COSTA",
    latitude: -16.658936,
    longitude: -49.1893624,
  },
  {
    Parceiro: "SMART TEND TUDO OLIMPICO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74922060",
    Bairro: "JARDIM OLIMPICO",
    Rua: "MONTE CARLO",
    latitude: -16.7526945,
    longitude: -49.2231912,
  },
  {
    Parceiro: "SUPEMERCADO REIS JD DOM FERNANDO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74765450",
    Bairro: "JARDIM DOM FERNANDO I",
    Rua: "CINCO DE MARCO",
    latitude: -16.664376,
    longitude: -49.1905276,
  },
  {
    Parceiro: "SUPER CENTER",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74786441",
    Bairro: "RESIDENCIAL PAULO ESTRELA",
    Rua: "DOUTOR JAHYR ABRAO ESTRELA",
    latitude: -16.6427146,
    longitude: -49.17935010000001,
  },
  {
    Parceiro: "SUPER LÍDER",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74976020",
    Bairro: "JARDIM BELO HORIZONTE - CONTINUACAO",
    Rua: "BRASIL",
    latitude: -16.8299101,
    longitude: -49.2504668,
  },
  {
    Parceiro: "SUPER ZE SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74805100",
    Bairro: "JARDIM GOIAS",
    Rua: "FUED JOSE SEBBA",
    latitude: -16.6871581,
    longitude: -49.2446302,
  },
  {
    Parceiro: "SUPERMERCADO ALVES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74770050",
    Bairro: "VILA PEDROSO",
    Rua: "CENTRAL",
    latitude: -16.6595698,
    longitude: -49.1805773,
  },
  {
    Parceiro: "SUPERMERCADO BARATEIRO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250448",
    Bairro: "CONJUNTO MORADA DO MORRO",
    Rua: "DO COMERCIO",
    latitude: -16.6930301,
    longitude: -49.12003869999999,
  },
  {
    Parceiro: "SUPERMERCADO BARBOSA - JARDIM LAGEADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74785050",
    Bairro: "JARDIM LAGEADO",
    Rua: "JL7",
    latitude: -16.6457244,
    longitude: -49.18900559999999,
  },
  {
    Parceiro: "SUPERMERCADO BARBOSA - JARDIM GUANABARA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74675050",
    Bairro: "JARDIM GUANABARA",
    Rua: "ARAGUARI",
    latitude: -16.624743,
    longitude: -49.2208677,
  },
  {
    Parceiro: "SUPERMERCADO BARROS LOJA 1",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74730435",
    Bairro: "JARDIM BRASIL",
    Rua: "PERIMETRAL 6",
    latitude: -16.6941547,
    longitude: -49.21251729999999,
  },
  {
    Parceiro: "SUPERMERCADO BARROS LOJA 2",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75254782",
    Bairro: "CONJUNTO MARGARIDA PROCOPIO",
    Rua: "FILOSTRO CARNEIRO",
    latitude: -16.6908493,
    longitude: -49.18785889999999,
  },
  {
    Parceiro: "SUPERMERCADO BOM SUCESSO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75261507",
    Bairro: "VILA BONSUCESSO",
    Rua: "5",
    latitude: -16.7088967,
    longitude: -49.0872094,
  },
  {
    Parceiro: "SUPERMERCADO CANEDAO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75261423",
    Bairro: "JARDIM TODOS OS SANTOS",
    Rua: "DESEMBARGADOR MATA TEIXEIRA",
    latitude: -16.7037232,
    longitude: -49.0916626,
  },
  {
    Parceiro: "SUPERMERCADO CELTA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74785190",
    Bairro: "SETOR RECANTO DAS MINAS GERAIS",
    Rua: "SR 48",
    latitude: -16.6502666,
    longitude: -49.1796029,
  },
  {
    Parceiro: "SUPERMERCADO CENTRAL",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75262712",
    Bairro: "RESIDENCIAL RAVENA",
    Rua: "RR-01",
    latitude: -16.7001459,
    longitude: -49.0999618,
  },
  {
    Parceiro: "SUPERMERCADO CLAYBOM",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74870340",
    Bairro: "CONJUNTO FABIANA",
    Rua: "4",
    latitude: -16.7296445,
    longitude: -49.21547469999999,
  },
  {
    Parceiro: "SUPERMERCADO CONTORNO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74863127",
    Bairro: "JARDIM BELA VISTA",
    Rua: "CONTORNO",
    latitude: -16.7296296,
    longitude: -49.2303737,
  },
  {
    Parceiro: "SUPERMERCADO DANY",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74710250",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "BOGOTA",
    latitude: -16.6794382,
    longitude: -49.2182748,
  },
  {
    Parceiro: "SUPERMERCADO DO BAIANO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74703060",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "BUENOS AIRES",
    latitude: -16.6688431,
    longitude: -49.2089647,
  },
  {
    Parceiro: "SUPERMERCADO ECONOMIA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75261592",
    Bairro: "SAO FRANCISCO",
    Rua: "SF 1",
    latitude: -16.7063515,
    longitude: -49.08509919999999,
  },
  {
    Parceiro: "SUPERMERCADO ECONÔMICO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74710400",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "LUZIANIA",
    latitude: -16.6727839,
    longitude: -49.2113741,
  },
  {
    Parceiro: "SUPERMERCADO FRANÇA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75264123",
    Bairro: "RESIDENCIAL PRADO",
    Rua: "RP 18",
    latitude: -16.7238894,
    longitude: -49.0934749,
  },
  {
    Parceiro: "SUPERMERCADO GALEGÃO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74923020",
    Bairro: "CHACARAS SAO PEDRO",
    Rua: "2",
    latitude: -16.7784539,
    longitude: -49.2244267,
  },
  {
    Parceiro: "SUPERMERCADO J8",
    Cidade: "Bonfinopolis",
    Estado: "GO",
    CEP: "75195000",
    Bairro: "CENTRO",
    Rua: "1",
    latitude: -16.6191181,
    longitude: -48.9616468,
  },
  {
    Parceiro: "SUPERMERCADO KILÃO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74765260",
    Bairro: "JARDIM DOM FERNANDO II",
    Rua: "DF 217",
    latitude: -16.6666685,
    longitude: -49.1915625,
  },
  {
    Parceiro: "SUPERMERCADO LAR FELIZ LOJA 01",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75256212",
    Bairro: "JARDIM DAS OLIVEIRAS II",
    Rua: "SENADOR CANEDO",
    latitude: -16.6529363,
    longitude: -49.1542811,
  },
  {
    Parceiro: "SUPERMERCADO LAR FELIZ LOJA 02",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250059",
    Bairro: "CONJUNTO HABITACIONAL JARDIM SABIA",
    Rua: "FLAMBOYANT",
    latitude: -16.7010962,
    longitude: -49.102208,
  },
  {
    Parceiro: "SUPERMERCADO LAR FELIZ LOJA 03",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74905620",
    Bairro: "VILA BRASILIA I",
    Rua: "PARECIS",
    latitude: -16.8226787,
    longitude: -49.2473476,
  },
  {
    Parceiro: "SUPERMERCADO LARANJEIRAS",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74920801",
    Bairro: "CHACARAS BELA VISTA",
    Rua: "DO MATO DENTRO",
    latitude: -16.742096,
    longitude: -49.2315559,
  },
  {
    Parceiro: "SUPERMERCADO LÍDER",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75259022",
    Bairro: "RESIDENCIAL BOA ESPERANCA",
    Rua: "ANTONIO FLAVIO LIMA",
    latitude: -16.6709424,
    longitude: -49.1062159,
  },
  {
    Parceiro: "SUPERMERCADO LOPES",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75256207",
    Bairro: "JARDIM DAS OLIVEIRAS I",
    Rua: "SENADOR CANEDO",
    latitude: -16.6568651,
    longitude: -49.1627634,
  },
  {
    Parceiro: "SUPERMERCADO LOTTOS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74770290",
    Bairro: "VILA CONCORDIA",
    Rua: "COUTO DE MAGALHAES",
    latitude: -16.6629498,
    longitude: -49.18410369999999,
  },
  {
    Parceiro: "SUPERMERCADO MANANCIAL - GOIÂNIA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74680780",
    Bairro: "JARDIM GUANABARA III",
    Rua: "GB9",
    latitude: -16.6122281,
    longitude: -49.2108853,
  },
  {
    Parceiro: "SUPERMERCADO MONTE SIÃO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74705280",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "SIMON BOLIVAR",
    latitude: -16.6768023,
    longitude: -49.2150353,
  },
  {
    Parceiro: "SUPERMERCADO MOURA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250000",
    Bairro: "RESIDENCIAL JARDIM CANEDO",
    Rua: "FERNANDO PESSOA",
    latitude: -16.6950464,
    longitude: -49.10806669999999,
  },
  {
    Parceiro: "SUPERMERCADO NOSSA SRA. APARECIDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74713440",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "NEW YORK",
    latitude: -16.6757973,
    longitude: -49.2193122,
  },
  {
    Parceiro: "SUPERMERCADO NOSSO LAR",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74922685",
    Bairro: "SITIOS SANTA LUZIA",
    Rua: "VEREADOR GERALDO PADEIRO",
    latitude: -16.7462805,
    longitude: -49.2304714,
  },
  {
    Parceiro: "SUPERMERCADO OLIVEIRA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75256224",
    Bairro: "JARDIM DAS OLIVEIRAS II",
    Rua: "JO 12",
    latitude: -16.6530435,
    longitude: -49.1513458,
  },
  {
    Parceiro: "SUPERMERCADO PARAÍSO",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75260243",
    Bairro: "RESIDENCIAL PARAISO",
    Rua: "RP 9",
    latitude: -16.6869298,
    longitude: -49.0908385,
  },
  {
    Parceiro: "SUPERMERCADO PITASSILGO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74560-540",
    Bairro: "SETOR CENTRO OESTE",
    Rua: "MARECHAL RONDON",
    latitude: -16.6542605,
    longitude: -49.2727631,
  },
  {
    Parceiro: "SUPERMERCADO PLANALTO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74650090",
    Bairro: "SETOR NEGRAO DE LIMA",
    Rua: "DONA SANTINHA",
    latitude: -16.6529925,
    longitude: -49.2429072,
  },
  {
    Parceiro: "SUPERMERCADO PONTO CERTO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74765480",
    Bairro: "JARDIM DOM FERNANDO I",
    Rua: "BOA ESPERANCA",
    latitude: -16.6625808,
    longitude: -49.1901645,
  },
  {
    Parceiro: "SUPERMERCADO REIS GUANABARA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74680770",
    Bairro: "JARDIM GUANABARA III",
    Rua: "GB8",
    latitude: -16.6150211,
    longitude: -49.2125613,
  },
  {
    Parceiro: "SUPERMERCADO RIO JORDÃO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74786350",
    Bairro: "RESIDENCIAL RIO JORDAO",
    Rua: "SR 7",
    latitude: -16.6427681,
    longitude: -49.1824615,
  },
  {
    Parceiro: "SUPERMERCADO SANTA ROSA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75251470",
    Bairro: "SETOR CENTRAL 2",
    Rua: "DA ESTACAO",
    latitude: -16.7112694,
    longitude: -49.0979774,
  },
  {
    Parceiro: "SUPERMERCADO SÃO PAULO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74780250",
    Bairro: "SANTO HILARIO",
    Rua: "HILARIO SEBASTIAO DE FIGUEIREDO",
    latitude: -16.6490259,
    longitude: -49.1929648,
  },
  {
    Parceiro: "SUPERMERCADO SHALLON LOJA 01",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74715010",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "URUGUAIANA",
    latitude: -16.6870595,
    longitude: -49.2236276,
  },
  {
    Parceiro: "SUPERMERCADO SHALLON LOJA 02",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75260654",
    Bairro: "RESIDENCIAL ECOLOGICO ARAGUAIA",
    Rua: "A-9",
    latitude: -16.6911312,
    longitude: -49.065957,
  },
  {
    Parceiro: "SUPERMERCADO SILVA",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75256269",
    Bairro: "JARDIM DAS OLIVEIRAS II",
    Rua: "JO 45",
    latitude: -16.6560878,
    longitude: -49.1520659,
  },
  {
    Parceiro: "SUPERMERCADO SONHO VERDE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74723280",
    Bairro: "AGUA BRANCA",
    Rua: "11",
    latitude: -16.6580296,
    longitude: -49.2462631,
  },
  {
    Parceiro: "SUPERMERCADO TATI",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74785132",
    Bairro: "SETOR RECANTO DAS MINAS GERAIS",
    Rua: "ESMERALDA",
    latitude: -16.6442677,
    longitude: -49.1896578,
  },
  {
    Parceiro: "SUPERMERCADO TOPÁZIO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74735170",
    Bairro: "JARDIM CALIFORNIA",
    Rua: "TOPAZIO",
    latitude: -16.6830202,
    longitude: -49.2068673,
  },
  {
    Parceiro: "TREVAO SUPERMERCADO",
    Cidade: "Bela Vista De Goias",
    Estado: "GO",
    CEP: "75240000",
    Bairro: "RESIDENCIAL VIA FLORES",
    Rua: "JOAQUIM BUENO TELES",
    latitude: -16.9565581,
    longitude: -48.961325,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO - SENADOR CANEDO - LOJA 01",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75251491",
    Bairro: "SETOR CENTRAL",
    Rua: "SANTANA",
    latitude: -16.7138464,
    longitude: -49.0921692,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO - AP. GOIÂNIA - LOJA 01",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74356002",
    Bairro: "RESIDENCIAL ITAIPU",
    Rua: "RIO VERMELHO",
    latitude: -16.7834785,
    longitude: -49.3646926,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO - GOIÂNIA - LOJA 02",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74930600",
    Bairro: "SETOR GARAVELO",
    Rua: "UNIAO",
    latitude: -16.7562671,
    longitude: -49.33529739999999,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO - SENADOR CANEDO - LOJA 03",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250000",
    Bairro: "JARDIM TODOS OS SANTOS",
    Rua: "VEREADOR JOSE EDUARDO",
    latitude: -16.6998007,
    longitude: -49.0920193,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO - SENADOR CANEDO - LOJA 04",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75254647",
    Bairro: "VILA GALVAO",
    Rua: "IPAMERI",
    latitude: -16.6986179,
    longitude: -49.1846101,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO - BELA VISTA - LOJA 05",
    Cidade: "Bela Vista De Goias",
    Estado: "GO",
    CEP: "75240000",
    Bairro: "CENTRO",
    Rua: "ANTENOR DE AMORIM",
    latitude: -16.9695228,
    longitude: -48.9530016,
  },
  {
    Parceiro: "SMART TEND TUDO ATHENEU",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74890670",
    Bairro: "PARQUE ATHENEU",
    Rua: "2012 UNIDADE 201",
    latitude: -16.7492357,
    longitude: -49.19817579999999,
  },
  {
    Parceiro: "SUPERMERCADO UNIÃO - SENADOR CANEDO - LOJA 02",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75262352",
    Bairro: "VILA SAO SEBASTIAO",
    Rua: "104",
    latitude: -16.7157952,
    longitude: -49.0902748,
  },
  {
    Parceiro: "SUPER MIX ATACADO E VAREJO",
    Cidade: "Salvador",
    Estado: "BA",
    CEP: "40720070",
    Bairro: "PERIPERI",
    Rua: "DOUTOR ALMEIDA LIMA",
    latitude: -12.8658039,
    longitude: -38.4714575,
  },
  {
    Parceiro: "PEG PAG REAL",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77023528",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ACSV SE 102",
    latitude: -10.2431394,
    longitude: -48.320953,
  },
  {
    Parceiro: "SUPER 10 SUPERMERCADO",
    Cidade: "Itaucu",
    Estado: "GO",
    CEP: "75450000",
    Bairro: "CENTRO",
    Rua: "EURIPEDES DE ASSIS",
    latitude: -16.1979099,
    longitude: -49.6042869,
  },
  {
    Parceiro: "VERDURÃO CENTER",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75800108",
    Bairro: "VILA SANTA MARIA",
    Rua: "DOM PEDRO II",
    latitude: -17.8888441,
    longitude: -51.72415280000001,
  },
  {
    Parceiro: "NOSSA KAZA F01 - VALPARAÍSO - JARDIM CÉU AZUL",
    Cidade: "Valparaiso De Goias",
    Estado: "GO",
    CEP: "72871064",
    Bairro: "JARDIM CEU AZUL",
    Rua: "64",
    latitude: -16.0497919,
    longitude: -48.0135795,
  },
  {
    Parceiro: "ATACADÃO DIADIA - CENTRO TAQUARALTO",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77064580",
    Bairro: "CENTRO (TAQUARALTO)",
    Rua: "TOCANTINS",
    latitude: -10.3474149,
    longitude: -48.2911338,
  },
  {
    Parceiro: "ATACADÃO DIADIA - MORADA DO SOL",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77066046",
    Bairro: "SETOR MORADA DO SOL (TAQUARALTO)",
    Rua: "TAQUARUSSU",
    latitude: -10.3573511,
    longitude: -48.2841556,
  },
  {
    Parceiro: "BRETAS B545",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74215170",
    Bairro: "SETOR BUENO",
    Rua: "T 55",
    latitude: -16.7038888,
    longitude: -49.2680413,
  },
  {
    Parceiro: "BRETAS B615",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74230180",
    Bairro: "SETOR BUENO",
    Rua: "T-0070",
    latitude: -16.7083226,
    longitude: -49.2740429,
  },
  {
    Parceiro: "SUPERMERCADO LEMOS",
    Cidade: "Guaraí",
    Estado: "TO",
    CEP: "77700000",
    Bairro: "CENTRO",
    Rua: "BERNARDO SAYÃO",
    latitude: -8.836021600000002,
    longitude: -48.5124708,
  },
  {
    Parceiro: "EMBALAGENS FORTALEZA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74025020",
    Bairro: "SETOR CENTRAL",
    Rua: "4",
    latitude: -16.6738927,
    longitude: -49.2598889,
  },
  {
    Parceiro: "GIGLIOS SUPERMERCADO",
    Cidade: "Guaraí",
    Estado: "TO",
    CEP: "77700000",
    Bairro: "CENTRO",
    Rua: "PERNAMBUCO ESQUINA COM RUA MATO GROSSO",
    latitude: -8.749337499999999,
    longitude: -48.4787258,
  },
  {
    Parceiro: "VERDURAO ALVORADA",
    Cidade: "Alvorada",
    Estado: "TO",
    CEP: "77480000",
    Bairro: "CENTRO",
    Rua: "BERNARDO SAYÃO",
    latitude: -12.4813039,
    longitude: -49.1209435,
  },
  {
    Parceiro: "EMBALAGENS SAO PATRÍCIO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74503100",
    Bairro: "SETOR CAMPINAS",
    Rua: "ANHANGUERA",
    latitude: -16.6608843,
    longitude: -49.2927256,
  },
  {
    Parceiro: "COMERCIAL NIPON",
    Cidade: "Guaraí",
    Estado: "TO",
    CEP: "77700000",
    Bairro: "CENTRO",
    Rua: "BERNARDO SAYÃO",
    latitude: -8.8306319,
    longitude: -48.5117804,
  },
  {
    Parceiro: "GIGLIOS SUPERMERCADO",
    Cidade: "Pedro Afonso",
    Estado: "TO",
    CEP: "77710000",
    Bairro: "SETOR AEROPORTO",
    Rua: "JOAO DAMASCENO DE SA",
    latitude: -8.975519499999999,
    longitude: -48.1736365,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - LAGO SUL - SHI S CL QIS CJ F",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71615560",
    Bairro: "SETOR DE HABITAÇÕES INDIVIDUAIS SUL",
    Rua: "SHIS QI 5 BLOCO F",
    latitude: -15.8520806,
    longitude: -47.8945655,
  },
  {
    Parceiro: "SUPERMERCADO IGUATU",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77405090",
    Bairro: "SETOR CENTRAL",
    Rua: "PRESIDENTE CASTELO BRANCO",
    latitude: -11.7212819,
    longitude: -49.0700972,
  },
  {
    Parceiro: "MATHEUS SUPERMERCADO",
    Cidade: "Formoso Do Araguaia",
    Estado: "TO",
    CEP: "77470000",
    Bairro: "CENTRO",
    Rua: "RIO FORMOSO",
    latitude: -11.8057672,
    longitude: -49.5305451,
  },
  {
    Parceiro: "MERCEARIA E BAR GOMES",
    Cidade: "Araguacu",
    Estado: "TO",
    CEP: "77475000",
    Bairro: "SETOR AEROPORTO",
    Rua: "JOSE DE ALENCAR FEITOSA",
    latitude: -12.9257288,
    longitude: -49.8300402,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - ASA SUL - SHCS CR",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70381515",
    Bairro: "ASA SUL",
    Rua: "SHCS",
    latitude: -15.8278269,
    longitude: -47.9273007,
  },
  {
    Parceiro: "CASA DA PARMEGIANA",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680490",
    Bairro: "ESTÂNCIA ITANHANGÁ",
    Rua: "F 2",
    latitude: -17.743004,
    longitude: -48.6266228,
  },
  {
    Parceiro: "MIX OLIVEIRA",
    Cidade: "Mata De Sao Joao",
    Estado: "BA",
    CEP: "48280000",
    Bairro: "PRAIA DO FORTE",
    Rua: "DO GORDINHO",
    latitude: -12.574764,
    longitude: -38.00500299999999,
  },
  {
    Parceiro: "SEMPRE SEIS SUPERMERCADO",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77403050",
    Bairro: "CENTRO",
    Rua: "PARANA",
    latitude: -11.7244438,
    longitude: -49.0613827,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - GOIÂNIA - SETOR BUENO - T15 C/ T68",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74230010",
    Bairro: "SETOR BUENO",
    Rua: "T 15 C/ RUA T 68",
    latitude: -16.7099566,
    longitude: -49.2722729,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - CAMPO GRANDE - VILA DOS POLONES - ALV. AZEVEDO",
    Cidade: "Campo Grande",
    Estado: "MS",
    CEP: "79032210",
    Bairro: "VILA DO POLONES",
    Rua: "ALVARES DE AZEVEDO",
    latitude: -20.436865,
    longitude: -54.5782253,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - GOIÂNIA - JARDIM GOIAS - RUA 12",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74810150",
    Bairro: "JARDIM GOIAS",
    Rua: "E E RUA 12",
    latitude: -16.6779734,
    longitude: -49.2714309,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - ASA SUL - SHCS 304/305",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70310500",
    Bairro: "ASA SUL",
    Rua: "EQ 304/305",
    latitude: -15.8202434,
    longitude: -47.9045093,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - ASA SUL - SHCS 308/309",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70310500",
    Bairro: "ASA SUL",
    Rua: "EQ 308/309",
    latitude: -15.8202434,
    longitude: -47.9045093,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - ASAL SUL - SHCS 406/407",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70255400",
    Bairro: "ASA SUL",
    Rua: "SHCS EQ 406/407",
    latitude: -15.8198941,
    longitude: -47.8945467,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB -ASA NORTE - EQN 404/405",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70310500",
    Bairro: "ASA NORTE",
    Rua: "EQ 404/405 BL A",
    latitude: -15.7730281,
    longitude: -47.8753095,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - LAGOA NORTE - SHIN QI2",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71503501",
    Bairro: "SETOR HABITACIONAL TAQUARI (LAGO NORTE)",
    Rua: "SHIN",
    latitude: -15.7200238,
    longitude: -47.88282299999999,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - PLANO PILOTO - EQN 508/509",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70310500",
    Bairro: "PLANO PILOTO",
    Rua: "EQ 508/509",
    latitude: -15.7631289,
    longitude: -47.89044819999999,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - SETOR SUDOESTE - CCSW 06",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70610200",
    Bairro: "SETOR SUDOESTE",
    Rua: "CCSW 06",
    latitude: -15.797302,
    longitude: -47.9286266,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - GUARÁ I - SRIA Q&2",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70310500",
    Bairro: "GUARÁ II",
    Rua: "SRIA Q E 2",
    latitude: -15.8183276,
    longitude: -47.9832651,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - BSB - ÁGUAS CLARAS - 206 LOTE",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71925180",
    Bairro: "SUL (AGUAS CLARAS)",
    Rua: "206 LOTE",
    latitude: -15.8434663,
    longitude: -48.0228349,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - GOIÂNIA - SETOR BUENO - T63",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74230105",
    Bairro: "SETOR BUENO",
    Rua: "T 63 C/ AVENIDA T-5 E RUA T-15",
    latitude: -16.7071938,
    longitude: -49.2691638,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - GOIÂNIA - SETOR LESTE - REP. LIBANO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74125125",
    Bairro: "SETOR OESTE",
    Rua: "REPUBLICA DO LIBANO",
    latitude: -16.6848554,
    longitude: -49.2714702,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - UBERLÂNDIA - PIO XII",
    Cidade: "Uberlandia",
    Estado: "MG",
    CEP: "38411106",
    Bairro: "MORADA DA COLINA",
    Rua: "NICOMEDES ALVES DOS SANTOS",
    latitude: -18.9418742,
    longitude: -48.2742623,
  },
  {
    Parceiro: "DUPIPE DISTRIBUIDORA",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77403050",
    Bairro: "SETOR CENTRAL",
    Rua: "PARANA",
    latitude: -11.725921588269172,
    longitude: -49.0730636808755,
  },
  {
    Parceiro: "SUPERMERCADO SANTA RITA",
    Cidade: "Santa Rita Do Tocantins",
    Estado: "TO",
    CEP: "77565000",
    Bairro: "CENTRO",
    Rua: "URSULINA ALVES CARVALHO",
    latitude: -10.8645705,
    longitude: -48.9079185,
  },
  {
    Parceiro: "MERCADAO SUPERMERCADO",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75800257",
    Bairro: "SETOR IRACEMA",
    Rua: "MIRANDA DE CARVALHO",
    latitude: -17.8848846,
    longitude: -51.7267181,
  },
  {
    Parceiro: "MERCEARIA NB",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74835370",
    Bairro: "PARQUE AMAZONAS",
    Rua: "DONA ANA NUNES DE MORAIS",
    latitude: -16.7269076,
    longitude: -49.278073,
  },
  {
    Parceiro: "ARMAZEM DO VILMAR",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74465539",
    Bairro: "JARDIM AMERICA",
    Rua: "C 199",
    latitude: -16.6979093,
    longitude: -49.2844311,
  },
  {
    Parceiro: "FORTE ATACAREJO - JOÃO BRAZ",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74483550",
    Bairro: "PRQ JOAO BRAZ - CIDADE INDUSTRIAL",
    Rua: "TOQUIO",
    latitude: -16.6847317,
    longitude: -49.35451090000001,
  },
  {
    Parceiro: "BRETAS B516",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74863250",
    Bairro: "JD. BELA VISTA",
    Rua: "CONTORNO",
    latitude: -16.6868912,
    longitude: -49.2647943,
  },
  {
    Parceiro: "BRETAS B633",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74475115",
    Bairro: "SETOR MORADA DO SOL",
    Rua: "MANGALO",
    latitude: -16.6176189,
    longitude: -49.3218922,
  },
  {
    Parceiro: "BRETAS B644",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74934575",
    Bairro: "CARDOSO",
    Rua: "EPIACAUBA",
    latitude: -16.7586355,
    longitude: -49.320107,
  },
  {
    Parceiro: "BRETAS B645",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75080240",
    Bairro: "CIDADE JARDIM",
    Rua: "BRASILIA",
    latitude: -16.3220083,
    longitude: -48.9478439,
  },
  {
    Parceiro: "BRETAS B648",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74968350",
    Bairro: "JARDIM MONTE CRISTO",
    Rua: "INDEPENDENCIA",
    latitude: -16.8237016,
    longitude: -49.277331,
  },
  {
    Parceiro: "BRETAS B651",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74723100",
    Bairro: "AGUA BRANCA",
    Rua: "ROLF WIEST",
    latitude: -16.6929227,
    longitude: -49.2296697,
  },
  {
    Parceiro: "BRETAS B656",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74440250",
    Bairro: "SETOR SAO JOSE",
    Rua: "PADRE WENDEL",
    latitude: -16.6622428,
    longitude: -49.3046789,
  },
  {
    Parceiro: "BRETAS B658",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74486470",
    Bairro: "CONDOMINIO RESIDENCIAL SANTA RITA",
    Rua: "SANTA MARIA",
    latitude: -16.6868912,
    longitude: -49.2647943,
  },
  {
    Parceiro: "BRETAS B662",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250005",
    Bairro: "PARQUE ALVORADA",
    Rua: "DOM EMANUEL",
    latitude: -16.688569,
    longitude: -49.1100494,
  },
  {
    Parceiro: "BRETAS B525",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74655030",
    Bairro: "VILA JARAGUA",
    Rua: "ENGENHEIRO FUAD RASSI",
    latitude: -16.6528227,
    longitude: -49.2541156,
  },
  {
    Parceiro: "BRETAS B527",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74043010",
    Bairro: "SETOR CENTRAL",
    Rua: "ANHANGUERA",
    latitude: -16.6716683,
    longitude: -49.2562665,
  },
  {
    Parceiro: "BRETAS B538",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74465170",
    Bairro: "JARDIM LISBOA",
    Rua: "INDEPENDENCIA",
    latitude: -16.6868912,
    longitude: -49.2647943,
  },
  {
    Parceiro: "BRETAS B541",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74315020",
    Bairro: "JARDIM ANA LUCIA",
    Rua: "ARAXA",
    latitude: -16.6968023,
    longitude: -49.3115885,
  },
  {
    Parceiro: "BRETAS B551",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74410200",
    Bairro: "SETOR SOL NASCENTE",
    Rua: "T 2",
    latitude: -16.6853164,
    longitude: -49.2945236,
  },
  {
    Parceiro: "BRETAS B619",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74770030",
    Bairro: "VILA PEDROSO",
    Rua: "ANÁPOLIS",
    latitude: -16.6593305,
    longitude: -49.1873787,
  },
  {
    Parceiro: "BRETAS B631",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74954310",
    Bairro: "PARQUE VEGA JARDIM",
    Rua: "ESCULTOR VEIGA VALLE",
    latitude: -16.7945019,
    longitude: -49.280024,
  },
  {
    Parceiro: "PÃODELLI",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75830000",
    Bairro: "BAIRRO SAO BENTO",
    Rua: "AVENIDA  ANTONIO CARLOS PANIAGO",
    latitude: -17.5670509,
    longitude: -52.5665436,
  },
  {
    Parceiro: "SUPERMERCADO PRO BRAZILIAN - SET LESTE VILA NOVA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74075010",
    Bairro: "SETOR AEROPORTO",
    Rua: "ANHANGUERA",
    latitude: -16.6711867,
    longitude: -49.2452518,
  },
  {
    Parceiro: "SUPERMERCADO PRO BRAZILIAN - SET LESTE VILA NOVA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74645020",
    Bairro: "SET LESTE VILA NOVA",
    Rua: "QUINTA AVENIDA",
    latitude: -16.663595,
    longitude: -49.24586499999999,
  },
  {
    Parceiro: "SUPERMERCADO PRO BRAZILIAN - JARDIM AMÉRICA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74465539",
    Bairro: "BAIRRO JARDIM AMERIC",
    Rua: "T-9",
    latitude: -16.7085725,
    longitude: -49.2879909,
  },
  {
    Parceiro: "SUPERMERCADO PRO BRAZILIAN - JD BALNEARIO MEIA PONTE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74590510",
    Bairro: "JD BALNEARIO MEIA PONTE",
    Rua: "NEROPOLIS",
    latitude: -16.4063154,
    longitude: -49.2197304,
  },
  {
    Parceiro: "SUPERMERCADO PRO BRAZILIAN - JD ATLANTICO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74343010",
    Bairro: "JD ATLANTICO",
    Rua: "IPANEMA",
    latitude: -16.7328181,
    longitude: -49.3061568,
  },
  {
    Parceiro: "SUPERMERCADO PRO BRAZILIAN - LOT CELINA PARK",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74373130",
    Bairro: "LOTEAMENTO CELINA PARK",
    Rua: "CP 6",
    latitude: -16.7096456,
    longitude: -49.3220018,
  },
  {
    Parceiro: "CONVENIENCIA POSTO COMETA",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77410010",
    Bairro: "LOTEAMENTO NOSSA SENHORA D'ABADIA",
    Rua: "GOIÁS",
    latitude: -11.7244438,
    longitude: -49.0613827,
  },
  {
    Parceiro: "SUPERMERCADO DOIS IRMAOS",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250793",
    Bairro: "RESIDENCIAL SANTA EDWIGES",
    Rua: "PROGRESSO",
    latitude: -16.7043481,
    longitude: -49.1247242,
  },
  {
    Parceiro: "COMERCIAL PARAISO",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77440470",
    Bairro: "SETOR AEROPORTO II",
    Rua: "11",
    latitude: -11.7124341,
    longitude: -49.0457097,
  },
  {
    Parceiro: "BEIRA RIO SUPERMERCADOS CD",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77402970",
    Bairro: "LOTEAMENTO SAO CRISTOVAO",
    Rua: "101",
    latitude: -11.7244438,
    longitude: -49.0613827,
  },
  {
    Parceiro: "SUPERMERCADO MOURA II",
    Cidade: "Formoso Do Araguaia",
    Estado: "TO",
    CEP: "77470000",
    Bairro: "CENTRO",
    Rua: "PERIMETRAL",
    latitude: -11.798978,
    longitude: -49.52927649999999,
  },
  {
    Parceiro: "SUPERMERCADO ADRIANO",
    Cidade: "Formoso Do Araguaia",
    Estado: "TO",
    CEP: "77470000",
    Bairro: "CENTRO",
    Rua: "ALFREDO CORREIA ESQUINA COM RUA 02",
    latitude: -11.8059191,
    longitude: -49.5296941,
  },
  {
    Parceiro: "EMPORIO GARCIA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74893360",
    Bairro: "PRQ ATHENEU",
    Rua: "9 UNIDADE 101",
    latitude: -16.744316,
    longitude: -49.2065768,
  },
  {
    Parceiro: "SUPERMERCADO TOCANTINS II",
    Cidade: "Alianca Do Tocantins",
    Estado: "TO",
    CEP: "77455000",
    Bairro: "CENTRO",
    Rua: "MARECHAL RONDON",
    latitude: -11.3045582,
    longitude: -48.9429234,
  },
  {
    Parceiro: "SUPERMERCADO SERVE LAR",
    Cidade: "Figueiropolis",
    Estado: "TO",
    CEP: "77465000",
    Bairro: "CENTRO",
    Rua: "FEDERAL",
    latitude: -12.1361854,
    longitude: -49.1700121,
  },
  {
    Parceiro: "CICLONE EMBALAGENS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74705020",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "CAMPOS ELISIOS",
    latitude: -16.6687873,
    longitude: -49.2203434,
  },
  {
    Parceiro: "SUPERMERCADO PRO BRAZILIAN - PARQUE AMAZÔNIA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74835300",
    Bairro: "PARQUE AMAZÔNIA",
    Rua: "TRANSBRASILIANA",
    latitude: -16.7235146,
    longitude: -49.2710867,
  },
  {
    Parceiro: "EMBALAGENS SILVA",
    Cidade: "Pontalina",
    Estado: "GO",
    CEP: "75620000",
    Bairro: "SETOR CENTRAL",
    Rua: "RUI BARBOSA",
    latitude: -17.5187068,
    longitude: -49.4498444,
  },
  {
    Parceiro: "BEIRA RIO SUPERMERCADO - ALTO DOS BURITIS",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77410343",
    Bairro: "ALTO DOS BURITIS",
    Rua: "PRESIDENTE COSTA E SILVA",
    latitude: -11.7340419,
    longitude: -49.0486367,
  },
  {
    Parceiro: "BEIRA RIO SUPERMERCADO - ALTO DA BOA VISTA",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77425500",
    Bairro: "ALTO DA BOA VISTA",
    Rua: "ANTONIO NUNES DA SILVA",
    latitude: -11.7291279,
    longitude: -49.0905065,
  },
  {
    Parceiro: "BEIRA RIO SUPERMERCADO - JARDIM TOCANTINS",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77440070",
    Bairro: "JARDIM TOCANTINS",
    Rua: "GOIÁS",
    latitude: -15.7050424,
    longitude: -49.36531489999999,
  },
  {
    Parceiro: "BEIRA RIO SUPERMERCADO - CENTRO - R. MARANHÃO",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77410020",
    Bairro: "CENTRO",
    Rua: "MARANHÃO",
    latitude: -11.7244438,
    longitude: -49.0613827,
  },
  {
    Parceiro: "BEIRA RIO SUPERMERCADO - SETOR CENTRAL - R. RIO GRANDE DO SUL",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77403070",
    Bairro: "SETOR CENTRAL",
    Rua: "RIO GRANDE DO SUL",
    latitude: -11.7288556,
    longitude: -49.0723613,
  },
  {
    Parceiro: "ATACADÃO BARATÃO CD",
    Cidade: "Araguaína",
    Estado: "TO",
    CEP: "77813330",
    Bairro: "JARDIM BEIRA LAGO",
    Rua: "MARIANA",
    latitude: -7.1856,
    longitude: -48.2079453,
  },
  {
    Parceiro: "ATACADISTA VIA GOIAS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74435300",
    Bairro: "AEROVIARIO",
    Rua: "<SEM ENDERECO>",
    latitude: -16.6672152,
    longitude: -49.30902150000001,
  },
  {
    Parceiro: "EMBALE",
    Cidade: "Araguaína",
    Estado: "TO",
    CEP: "77817070",
    Bairro: "JARDIM ESPLANADA",
    Rua: "SAO PAULO",
    latitude: -7.2018909,
    longitude: -48.23064549999999,
  },
  {
    Parceiro: "SUPER CENTRO ATACADISTA",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77023674",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "1012 SUL ALAMEDA 8",
    latitude: -10.2075943,
    longitude: -48.33787969999999,
  },
  {
    Parceiro: "AKEMI EMBALAGENS E FRIOS",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75251482",
    Bairro: "SETOR CENTRAL",
    Rua: "7 DE SETEMBRO",
    latitude: -16.7073865,
    longitude: -49.0943183,
  },
  {
    Parceiro: "SUPERMERCADO MOURA III",
    Cidade: "Formoso Do Araguaia",
    Estado: "TO",
    CEP: "77470000",
    Bairro: "SETOR ALIANCA",
    Rua: "AVENIDA ANHANGUERA",
    latitude: -11.81846,
    longitude: -49.5175293,
  },
  {
    Parceiro: "MOINHO MERCANTE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74371440",
    Bairro: "MOINHO DOS VENTOS",
    Rua: "SAO LUIZ",
    latitude: -16.7336979,
    longitude: -49.342462,
  },
  {
    Parceiro: "SUPERMERCADO MINI BOX - NORTE ALAMEDA 10",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77001272",
    Bairro: "PLANO DIRETOR NORTE",
    Rua: "ARNO 31 ALAMEDA 10 QI 12 LOTE 01 A (303 NORTE )",
    latitude: -10.1672772,
    longitude: -48.33756210000001,
  },
  {
    Parceiro: "SUPERMERCADO TIO PATINHAS",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77423030",
    Bairro: "WALDIR LINS I",
    Rua: "VP 2D",
    latitude: -11.738153,
    longitude: -49.08873819999999,
  },
  {
    Parceiro: "EMPORIO TREM BAO E COISA BOA - VILA OLEGARIO",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75691542",
    Bairro: "VILA OLEGARIO PINTO",
    Rua: "OROZIMBO CORREA NETO",
    latitude: -17.737391,
    longitude: -48.6232266,
  },
  {
    Parceiro: "EMPORIO TREM BAO E COISA BOA - DO TURISTA",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75696000",
    Bairro: "DO TURISTA",
    Rua: "DO TURISMO",
    latitude: -17.7379215,
    longitude: -48.6241565,
  },
  {
    Parceiro: "SUPER MARISTA",
    Cidade: "Trindade",
    Estado: "GO",
    CEP: "75380001",
    Bairro: "SETOR JARDIM MARISTA",
    Rua: "PRESIDENTE VARGAS",
    latitude: -16.640804,
    longitude: -49.4141632,
  },
  {
    Parceiro: "SUPERMERCADO PARA TODOS",
    Cidade: "Santa Helena De Goiás",
    Estado: "GO",
    CEP: "75920000",
    Bairro: "CENTRO",
    Rua: "JOSE ANTONIO DA SILVEIRA LEAO",
    latitude: -17.8118397,
    longitude: -50.6005687,
  },
  {
    Parceiro: "SUPERMERCADO PARA TODOS",
    Cidade: "Santa Helena De Goiás",
    Estado: "GO",
    CEP: "75920000",
    Bairro: "PEDROLINA",
    Rua: "JOSE F. DIAS",
    latitude: -17.8118397,
    longitude: -50.6005687,
  },
  {
    Parceiro: "SUPERMERCADO TOSTA - LOJA 1",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75800089",
    Bairro: "VILA SANTA MARIA",
    Rua: "ITARUMA",
    latitude: -17.8883078,
    longitude: -51.7232962,
  },
  {
    Parceiro: "SUPERMERCADO PIONEIRO EXPRESS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74823460",
    Bairro: "SET BELA VISTA",
    Rua: "S5",
    latitude: -16.7146712,
    longitude: -49.26028290000001,
  },
  {
    Parceiro: "SUPERMERCADO FRICALDAS",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75696070",
    Bairro: "DO TURISTA 2ª ETAPA",
    Rua: "ALFREDO DIAS DE MORAIS",
    latitude: -17.7238854,
    longitude: -48.6345164,
  },
  {
    Parceiro: "SUPER MANANCIAL",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74927020",
    Bairro: "PARQUE TRINDADE II",
    Rua: "ANTIGA GO-352",
    latitude: -16.7482427,
    longitude: -49.2050999,
  },
  {
    Parceiro: "ATACADAO CENTRO SUL - ARTHUR CATRAMBI",
    Cidade: "Salvador",
    Estado: "BA",
    CEP: "40411370",
    Bairro: "CALCADA",
    Rua: "ARTHUR CATRAMBI",
    latitude: -12.9461194,
    longitude: -38.4992658,
  },
  {
    Parceiro: "ATACADAO CENTRO SUL - NILO PECANHA",
    Cidade: "Salvador",
    Estado: "BA",
    CEP: "40411380",
    Bairro: "CALCADA",
    Rua: "NILO PECANHA",
    latitude: -12.944044,
    longitude: -38.4966235,
  },
  {
    Parceiro: "RIO QUENTE SUPERMERCADO",
    Cidade: "Rio Quente",
    Estado: "GO",
    CEP: "75667000",
    Bairro: "JARDIM ESPLANADA",
    Rua: "BRASILIA",
    latitude: -17.8086529,
    longitude: -48.7605938,
  },
  {
    Parceiro: "SUPERMERCADO ATUAL 01",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75696010",
    Bairro: "DO TURISTA",
    Rua: "PRESIDENTE CASTELO BRANCO",
    latitude: -17.7123917,
    longitude: -48.6028738,
  },
  {
    Parceiro: "H P M SUPERMERCADO",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680074",
    Bairro: "TERMAL",
    Rua: "MACHADO DE ASSIS",
    latitude: -17.7439529,
    longitude: -48.6283944,
  },
  {
    Parceiro: "SUPERMERCADO ATUAL",
    Cidade: "Rio Quente",
    Estado: "GO",
    CEP: "75667000",
    Bairro: "JARDIM ESPLANADA",
    Rua: "BRASILIA",
    latitude: -17.7718628,
    longitude: -48.755788,
  },
  {
    Parceiro: "SUPERMERCADO ATUAL - SOLAR DE CALDAS NOVAS",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75696057",
    Bairro: "SOLAR DE CALDAS NOVAS",
    Rua: "SANTO AMARO DE IPITANGA",
    latitude: -12.8973699,
    longitude: -38.3033967,
  },
  {
    Parceiro: "SUPERMERCADO ATUAL - CENTRO",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680029",
    Bairro: "CENTRO",
    Rua: "CAPITAO JOAO CRISOSTOMO",
    latitude: -17.7400492,
    longitude: -48.624671,
  },
  {
    Parceiro: "SUPERMERCADO BANDEIRANTE",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680124",
    Bairro: "BANDEIRANTES",
    Rua: "2",
    latitude: -17.7485525,
    longitude: -48.63312149999999,
  },
  {
    Parceiro: "NOSSA KAZA - BRASÍLIA - SETOR LESTE GAMA",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72450080",
    Bairro: "SETOR LESTE (GAMA)",
    Rua: "LESTE COM",
    latitude: -16.0234586,
    longitude: -48.0541712,
  },
  {
    Parceiro: "SUPER LIMA",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74986820",
    Bairro: "EXPANSUL",
    Rua: "IRMA CATARINA COLAVITTI",
    latitude: -16.814922,
    longitude: -49.2351597,
  },
  {
    Parceiro: "REDE STORE - APG - MANSÕES PARAÍSO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74952187",
    Bairro: "MANSÕES PARAÍSO",
    Rua: "H 152",
    latitude: -16.784464,
    longitude: -49.2779618,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - CD PE - CABO DE AGOSTINHO",
    Cidade: "Cabo De Santo Agostinho",
    Estado: "PE",
    CEP: "54503900",
    Bairro: "DISTRITO INDUSTRIAL DIPER",
    Rua: "BR-101 SUL 00KM 96,4",
    latitude: -8.2702074,
    longitude: -35.004219,
  },
  {
    Parceiro: "BONNO COMERCIAL",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75902510",
    Bairro: "JARDIM AMERICA",
    Rua: "LAUDEMIRO JOSE BUENO",
    latitude: -17.7857815,
    longitude: -50.9272324,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - CD RJ",
    Cidade: "Duque De Caxias",
    Estado: "RJ",
    CEP: "25225015",
    Bairro: "VILA SANTO ANTONIO",
    Rua: "WASHINGTON LUIZ",
    latitude: -22.6415235,
    longitude: -43.2836073,
  },
  {
    Parceiro: "FORTE ATACAREJO - JD PRESIDENTE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74353520",
    Bairro: "JD PRESIDENTE DUTRA",
    Rua: "RIO VERDE",
    latitude: -17.7896277,
    longitude: -50.9204571,
  },
  {
    Parceiro: "ATAKAÍ 25",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42825001",
    Bairro: "CATU DE ABRANTES (ABRANTES)",
    Rua: "BA 099 ESTRADA DO COCO",
    latitude: -12.8602634,
    longitude: -38.2865885,
  },
  {
    Parceiro: "PEDRO MINI BOX",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77249899",
    Bairro: "AREA RURAL DE PALMAS",
    Rua: "AGUA FRIA 2ª ETAPA LOTE 01 QD 03",
    latitude: -10.249091,
    longitude: -48.32428580000001,
  },
  {
    Parceiro: "CARREFOUR - BSB - GUARÁ II",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71218900",
    Bairro: "GUARÁ II",
    Rua: "SCEE SUL LOTE B",
    latitude: -15.8359059,
    longitude: -47.9792314,
  },
  {
    Parceiro: "CARREFOUR - BSB - ASA NORTE EQ 512/513",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70760502",
    Bairro: "ASA NORTE",
    Rua: "EQ 512/513 BLOCO A LOJA 50",
    latitude: -15.7506794,
    longitude: -47.89455160000001,
  },
  {
    Parceiro: "CARREFOUR - BSB - ASA SUL EQ310/311",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70362400",
    Bairro: "ASA SUL",
    Rua: "EQ 310/311 BLOCO A",
    latitude: -15.8344063,
    longitude: -47.9518285,
  },
  {
    Parceiro: "CARREFOUR - BSB - ASA SUL SHCS 402/403",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70236400",
    Bairro: "ASA SUL",
    Rua: "SHCS EQ 402/403",
    latitude: -15.8344063,
    longitude: -47.9518285,
  },
  {
    Parceiro: "CARREFOUR - BSB - ASA NORTE 504/505",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70730545",
    Bairro: "ASA NORTE",
    Rua: "EQN 504/505",
    latitude: -15.774779,
    longitude: -47.8876673,
  },
  {
    Parceiro: "CARREFOUR - BSB - LAGO SUL QI 25",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71660200",
    Bairro: "LAGO SUL",
    Rua: "CL QI 25 BLOCO G",
    latitude: -15.8427012,
    longitude: -47.8307876,
  },
  {
    Parceiro: "CARREFOUR - GYN - JARDINS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74810100",
    Bairro: "JARDINS GOIAS",
    Rua: "DEPUTADO JAMEL CECILIO",
    latitude: -16.7063946,
    longitude: -49.2382167,
  },
  {
    Parceiro: "CARREFOUR - APS - CIDADE JARDIM",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75080240",
    Bairro: "CIDADE JARDIM",
    Rua: "BRASILIA",
    latitude: -16.2459715,
    longitude: -48.9632124,
  },
  {
    Parceiro: "CARREFOUR - BSB - COPAIBA",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71919540",
    Bairro: "NORTE (AGUAS CLARAS)",
    Rua: "COPAIBA",
    latitude: -15.8317475,
    longitude: -48.0391246,
  },
  {
    Parceiro: "CARREFOUR - GYN - VILA BELA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74310320",
    Bairro: "VILA BELA",
    Rua: "T 9, ESQ C/ RUA FLEMINGTON",
    latitude: -16.7129409,
    longitude: -49.2994927,
  },
  {
    Parceiro: "CARREFOUR - BSB - LAGO SUL QI 13",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "71635013",
    Bairro: "LAGO SUL",
    Rua: "SHI/SUL QI 13 BLOCO J",
    latitude: -15.8427931,
    longitude: -47.8761731,
  },
  {
    Parceiro: "CARREFOUR - BSB - ASA NORTE SS36",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "70770916",
    Bairro: "ASA NORTE",
    Rua: "SETOR TERMINAL NORTE CONJUNTO J LOJA SS36 E SS40 1 SUBSOLO",
    latitude: -15.7634542,
    longitude: -47.8800995,
  },
  {
    Parceiro: "GRACA SUPERMERCADO",
    Cidade: "Alianca Do Tocantins",
    Estado: "TO",
    CEP: "77455000",
    Bairro: "CENTRO",
    Rua: "LUCY",
    latitude: -11.3054626,
    longitude: -48.9441397,
  },
  {
    Parceiro: "SANTOS SUPERMERCADO",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680001",
    Bairro: "JARDIM PRIVE DAS CALDAS",
    Rua: "MARGINAL",
    latitude: -17.7250041,
    longitude: -48.5964427,
  },
  {
    Parceiro: "SUPERMERCADO AQUI TEM",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680001",
    Bairro: "MANSOES DAS AGUAS QUENTES",
    Rua: "ONZE",
    latitude: -17.7108253,
    longitude: -48.6156125,
  },
  {
    Parceiro: "SUPER PADRAO SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74484070",
    Bairro: "LOT SOLANGE PARQUE I",
    Rua: "PH8",
    latitude: -16.6971995,
    longitude: -49.3533682,
  },
  {
    Parceiro: "FORTE BEBIDAS EMPORIO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74770200",
    Bairro: "VI PEDROSO",
    Rua: "8",
    latitude: -16.6563499,
    longitude: -49.1791686,
  },
  {
    Parceiro: "REDE STORE - GYN - BELA VISTA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74823420",
    Bairro: "SET BELA VISTA",
    Rua: "S1",
    latitude: -16.7209002,
    longitude: -49.2629581,
  },
  {
    Parceiro: "REDE STORE - GYN - SETOR URIAS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74565070",
    Bairro: "SETOR URIAS MAGALHAES",
    Rua: "RIO BRANCO",
    latitude: -16.6345264,
    longitude: -49.2613339,
  },
  {
    Parceiro: "REDE STORE - GYN - SETOR CANDIDA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74463330",
    Bairro: "SETOR CANDIDA DE MORAIS",
    Rua: "PERIMETRAL NORTE",
    latitude: -16.6504535,
    longitude: -49.3258351,
  },
  {
    Parceiro: "REDE STORE - GYN - SETOR DOS AFONSOS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74843710",
    Bairro: "SETOR DOS AFONSOS",
    Rua: "RIO VERDE",
    latitude: -16.7391569,
    longitude: -49.27202459999999,
  },
  {
    Parceiro: "REDE STORE - GYN - MARECHAL RONDON",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74560070",
    Bairro: "MARECHAL RONDON",
    Rua: "BERNARDO SAYÃO",
    latitude: -16.6605219,
    longitude: -49.2742466,
  },
  {
    Parceiro: "REDE STORE - GYN -  SET CAMPINAS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74503111",
    Bairro: "SET CAMPINAS",
    Rua: "ANHANGUERA",
    latitude: -16.6608843,
    longitude: -49.2927256,
  },
  {
    Parceiro: "REDE STORE - GYN - FAZENDA SAO JOSE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74496255",
    Bairro: "FAZENDA SAO JOSE",
    Rua: "GO-060",
    latitude: -16.7022382,
    longitude: -49.41610499999999,
  },
  {
    Parceiro: "REDE STORE - TRINDADE - VILA PADRE ETERNO",
    Cidade: "Trindade",
    Estado: "GO",
    CEP: "75388253",
    Bairro: "VILA PADRE ETERNO",
    Rua: "FRANCISCO PAULO RAMOS",
    latitude: -16.649254,
    longitude: -49.4950175,
  },
  {
    Parceiro: "AVAMTHE SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74423500",
    Bairro: "BRO CIDADE JARDIM",
    Rua: "JOSE GOMES BAILAO",
    latitude: -16.6771008,
    longitude: -49.3150115,
  },
  {
    Parceiro: "SUPERMERCADO REAL",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75106510",
    Bairro: "SETOR SUL",
    Rua: "LUIZ CARLOS DE SOUZA",
    latitude: -16.3691756,
    longitude: -48.948125,
  },
  {
    Parceiro: "REDE STORE - GYN - RESIDENCIAL ELDORADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74367640",
    Bairro: "RESIDENCIAL ELDORADO",
    Rua: "ANÁPOLIS",
    latitude: -16.7077402,
    longitude: -49.3285847,
  },
  {
    Parceiro: "REDE STORE - AP GYN - CIDADE LIVRE",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74970295",
    Bairro: "CIDADE LIVRE",
    Rua: "INDEPENDENCIA",
    latitude: -16.8230051,
    longitude: -49.286361,
  },
  {
    Parceiro: "REDE STORE - TRINDADE - VILA JARDIM SALVADOR",
    Cidade: "Trindade",
    Estado: "GO",
    CEP: "75388439",
    Bairro: "VILA JARDIM SALVADOR",
    Rua: "CORONEL ANACLETO",
    latitude: -16.6561363,
    longitude: -49.48820079999999,
  },
  {
    Parceiro: "REDE STORE - GYN - VILA MORAES",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75901507",
    Bairro: "VILA MORAIS",
    Rua: "PAULO ROBERTO CUNHA",
    latitude: -16.6868912,
    longitude: -49.2647943,
  },
  {
    Parceiro: "SUPERMERCADO SAMILLA",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77405040",
    Bairro: "CENTRO",
    Rua: "DELFINO AGUIAR",
    latitude: -11.7284217,
    longitude: -49.0581306,
  },
  {
    Parceiro: "SUPERMERCADO COMPRE BEM",
    Cidade: "Gurupi",
    Estado: "TO",
    CEP: "77430125",
    Bairro: "JARDIM MEDEIROS",
    Rua: "DUERE",
    latitude: -11.7154393,
    longitude: -49.0905206,
  },
  {
    Parceiro: "SUPER ECONOMIA",
    Cidade: "Sao Luis De Montes Belos    ",
    Estado: "GO",
    CEP: "76100000",
    Bairro: "SAO JOSE 2ª ETAPA",
    Rua: "SAO DOMINGOS QUADRA 111 LOTE 03",
    latitude: -16.5249592,
    longitude: -50.3794478,
  },
  {
    Parceiro: "MEGA CORUJAO",
    Cidade: "Feira De Santana",
    Estado: "BA",
    CEP: "44085370",
    Bairro: "SIM",
    Rua: "ARTEMIA PIRES FREITAS",
    latitude: -12.2467135,
    longitude: -38.9172828,
  },
  {
    Parceiro: "HIPER UNIMAR - BARRA DO POJUCA",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42841576",
    Bairro: "BARRA DO POJUCA (MONTE GORDO)",
    Rua: "FILOGONIO GOMES DE OLIVEIRA",
    latitude: -12.59123,
    longitude: -38.0452349,
  },
  {
    Parceiro: "HIPER UNIMAR",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42841576",
    Bairro: "BARRA DO POJUCA (MONTE GORDO)",
    Rua: "FILOGONIO GOMES DE OLIVEIRA",
    latitude: -12.5898658,
    longitude: -38.053463,
  },
  {
    Parceiro: "SUPERMERCADO SOL E MAR",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74371440",
    Bairro: "LOT MOINHO DOS VENTOS",
    Rua: "SAO LUIZ",
    latitude: -16.7341506,
    longitude: -49.3427274,
  },
  {
    Parceiro: "XAVIER SUPERMERCADOS",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75909836",
    Bairro: "RESIDENCIAL SOLAR DOS ATAIDES 1ª ETAPA",
    Rua: "JOSE GONCALVES ATAIDES",
    latitude: -17.7711418,
    longitude: -50.9457682,
  },
  {
    Parceiro: "FRUTARIA DO POVO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75901690",
    Bairro: "VILA SANTO ANDRE",
    Rua: "ALMIRO DE MORAES",
    latitude: -17.790821,
    longitude: -50.9305776,
  },
  {
    Parceiro: "COMERCIAL MONTIVIDIU - SETOR CENTRAL",
    Cidade: "Montividiu",
    Estado: "GO",
    CEP: "75915000",
    Bairro: "CENTRO",
    Rua: "FILOGONIO FARIA LEAO, ESQUINA COM A RUA FRANCISCO SALES ROCH",
    latitude: -17.4493278,
    longitude: -51.1770436,
  },
  {
    Parceiro: "SUPERMERCADO MARTINS - VILA MUTIRAO",
    Cidade: "Montividiu",
    Estado: "GO",
    CEP: "75915000",
    Bairro: "VILA MUTIRAO",
    Rua: "DAS VIOLETAS",
    latitude: -16.6167948,
    longitude: -49.35389319999999,
  },
  {
    Parceiro: "RESTAURANTE E BAR ECOLOGICO DA BERE",
    Cidade: "Filadelfia",
    Estado: "TO",
    CEP: "77795000",
    Bairro: "SET CENTRAL",
    Rua: "PRESIDENTE GETULIO VARGAS",
    latitude: -7.460219299999999,
    longitude: -47.7243139,
  },
  {
    Parceiro: "DOM JASON DISTRIBUIDOR",
    Cidade: "Paraíso Do Tocantins",
    Estado: "TO",
    CEP: "77600000",
    Bairro: "ZONA RURAL",
    Rua: "SANTA LUZIA",
    latitude: -10.1933316,
    longitude: -48.881298,
  },
  {
    Parceiro: "FABIANA DA SILVA TERRA LTDA",
    Cidade: "Santa Barbara D'Oeste",
    Estado: "SP",
    CEP: "13459600",
    Bairro: "ALAMBARI",
    Rua: "MARIO MORO",
    latitude: -23.5508874,
    longitude: -47.898432,
  },
  {
    Parceiro: "COMERCIAL REIS - JATAÍ - SETOR OESTE",
    Cidade: "Jatai",
    Estado: "GO",
    CEP: "75804095",
    Bairro: "SETOR OESTE",
    Rua: "MINAS GERAIS",
    latitude: -17.8820128,
    longitude: -51.7320952,
  },
  {
    Parceiro: "CEREALISTA E ATACADISTA AMIGAO",
    Cidade: "Porto Nacional",
    Estado: "TO",
    CEP: "77500000",
    Bairro: "JK NOVA CAPITAL",
    Rua: "BELMIRO DA SILVA PRADO, QUADRA 06. LOTE 12 A 14",
    latitude: -10.7069499,
    longitude: -48.4125873,
  },
  {
    Parceiro: "NOVO MIX ATACADO DE ALIMENTOS",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42820644",
    Bairro: "CAJAZEIRAS DE ABRANTES (ABRANTES)",
    Rua: "LAGOA BRANCA",
    latitude: -12.7001932,
    longitude: -38.325345,
  },
  {
    Parceiro: "PÃO DE AÇUCAR - MEIRELES FORTALEZA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60165078",
    Bairro: "SETOR GENTIL MEIRELES",
    Rua: "DA ABOLICAO",
    latitude: -3.7274463,
    longitude: -38.4954741,
  },
  {
    Parceiro: "PÃO DE AÇUCAR - FATIMA FORTALEZA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60055401",
    Bairro: "VILA FÁTIMA",
    Rua: "AGUANAMBI",
    latitude: -3.7486734,
    longitude: -38.5232261,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - CD - SP",
    Cidade: "São Paulo",
    Estado: "SP",
    CEP: "05161000",
    Bairro: "VILA JARAGUA",
    Rua: "TURISTICA DO JARAGUA",
    latitude: -23.4653035,
    longitude: -46.7559965,
  },
  {
    Parceiro: "FRUTAS DO GILMAR",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75681869",
    Bairro: "JARDIM DOS TURISTAS",
    Rua: "DO IPE",
    latitude: -17.7536042,
    longitude: -48.6200675,
  },
  {
    Parceiro: "TOTAL ATACADO",
    Cidade: "Lauro De Freitas",
    Estado: "BA",
    CEP: "42702420",
    Bairro: "CENTRO",
    Rua: "LUIZ TARQUINIO PONTES",
    latitude: -12.889622,
    longitude: -38.3166269,
  },
  {
    Parceiro: "DESCONTAO SUPERMERCADO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75902308",
    Bairro: "JARDIM MONDALE",
    Rua: "DAS ACACIAS ESQUINA COM A RUA BERNARDINO J. ATAIDES",
    latitude: -17.7812299,
    longitude: -50.9408092,
  },
  {
    Parceiro: "SACOLAO DO MAGRAO",
    Cidade: "Formoso Do Araguaia",
    Estado: "TO",
    CEP: "77470000",
    Bairro: "CENTRO",
    Rua: "TOMAZ EVANGELISTA ESQUINA COM RUA 10",
    latitude: -11.807187,
    longitude: -49.5215456,
  },
  {
    Parceiro: "COMIVA",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75830078",
    Bairro: "CENTRO",
    Rua: "DOZE",
    latitude: -17.5635333,
    longitude: -52.552764,
  },
  {
    Parceiro: "RFA DISTRIBUIDORA DE ALIMENTOS",
    Cidade: "Alagoinhas",
    Estado: "BA",
    CEP: "48010634",
    Rua: "URUGUAI",
    latitude: -12.1339689,
    longitude: -38.4211223,
  },
  {
    Parceiro: "EMPORIO JORDÃO",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75687606",
    Rua: "ARAXA",
    latitude: -17.7488628,
    longitude: -48.5999284,
  },
  {
    Parceiro: "GBARBOSA - CAMAÇARI BA",
    Cidade: "Camaçari",
    Estado: "BA",
    CEP: "42810260",
    Bairro: "POLO PETROQUIMICO",
    Rua: "DE LIGACAO CAMACARI",
    latitude: -12.6860533,
    longitude: -38.3541091,
  },
  {
    Parceiro: "GBARBOSA - NOSSA SENHORA DO SOCORRO - SE",
    Cidade: "Nossa Senhora Do Socorro",
    Estado: "SE",
    CEP: "49160000",
    Bairro: "SOBRADO",
    Rua: "BR 235, KM 04",
    latitude: -10.9064361,
    longitude: -37.1118102,
  },
  {
    Parceiro: "CASA DA CONFEITARIA - SETOR CENTRAL",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75024050",
    Bairro: "SETOR CENTRAL",
    Rua: "QUATORZE DE JULHO",
    latitude: -16.3256036,
    longitude: -48.9585698,
  },
  {
    Parceiro: "CASA DO CHOCOLATE - CENTRO",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75024050",
    Bairro: "CENTRO",
    Rua: "QUATORZE DE JULHO",
    latitude: -16.3259559,
    longitude: -48.9582321,
  },
  {
    Parceiro: "HDM DISTRIBUIDORA DE ARTIGOS PARA FESTAS",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75024050",
    Bairro: "SETOR CENTRAL",
    Rua: "QUATORZE DE JULHO",
    latitude: -16.3289154,
    longitude: -48.9580767,
  },
  {
    Parceiro: "SUPERMERCADO REIS",
    Cidade: "Quirinópolis",
    Estado: "GO",
    CEP: "75860000",
    Bairro: "CENTRO",
    Rua: "SANTOS DUMONT",
    latitude: -18.4435073,
    longitude: -50.4499133,
  },
  {
    Parceiro: "SUPERMERCADO PRATIKO - SETOR DOS AFONSOS",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74915290",
    Bairro: "SETOR DOS AFONSOS",
    Rua: "DOURADOS",
    latitude: -16.7408975,
    longitude: -49.2702031,
  },
  {
    Parceiro: "FACIL ATACAREJO",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680376",
    Bairro: "ESTANCIA ITANHANGA I",
    Rua: "D (RODOVIA GO 139)",
    latitude: -17.760747,
    longitude: -48.63477,
  },
  {
    Parceiro: "DA TERRA HORTIFRUTI",
    Cidade: "Senador Canedo",
    Estado: "GO",
    CEP: "75250041",
    Bairro: "CONJUNTO HABITACIONAL JARDIM SABIA",
    Rua: "PROGRESSO",
    latitude: -16.7001459,
    longitude: -49.0999618,
  },
  {
    Parceiro: "MUNDIAL SUPERMERCADOS",
    Cidade: "São Miguel Do Araguaia",
    Estado: "GO",
    CEP: "76590000",
    Bairro: "SETOR OESTE",
    Rua: "JOSE PEREIRA DO NASCIMENTO",
    latitude: -13.2783522,
    longitude: -50.1733283,
  },
  {
    Parceiro: "ATACADAO DOS PRECOS BAIXOS",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77006572",
    Bairro: "PLANO DIRETOR NORTE",
    Rua: "504 NORTE ALAMEDA 28",
    latitude: -10.1659678,
    longitude: -48.317553,
  },
  {
    Parceiro: "QUEIJARIA SANTA CLARA",
    Cidade: "Niquelandia",
    Estado: "GO",
    CEP: "76420000",
    Bairro: "VILA MARIA - CENTRO",
    Rua: "07 DE SETEMBRO",
    latitude: -14.4701185,
    longitude: -48.4574674,
  },
  {
    Parceiro: "ATACADAO DOS PRECOS BAIXOS",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77063160",
    Bairro: "JARDIM TAQUARI (TAQUARALTO)",
    Rua: "T 21 AVENIDA TLO 5, QC 02,",
    latitude: -10.3458476,
    longitude: -48.3330947,
  },
  {
    Parceiro: "ATACADAO DOS PRECOS BAIXOS",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77015648",
    Bairro: "PLANO DIRETOR SUL",
    Rua: "ARSO 42 ALAMEDA 32, HM 07, LOTE 02 - A, (405 SUL)",
    latitude: -10.2049835,
    longitude: -48.3487806,
  },
  {
    Parceiro: "ELIZABETE GONÇALVES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74710080",
    Bairro: "JARDIM NOVO MUNDO",
    Rua: "MISSISSIPI",
    latitude: -16.6774653,
    longitude: -49.2187467,
  },
  {
    Parceiro: "RODOSUL",
    Cidade: "Brasília",
    Estado: "DF",
    CEP: "72265150",
    Bairro: "SETOR INDUSTRIAL (CEILANDIA)",
    Rua: "QUADRA 15",
    latitude: -15.821435,
    longitude: -48.114201,
  },
  {
    Parceiro: "SUPER LEVE",
    Cidade: "Uruacu",
    Estado: "GO",
    CEP: "76400000",
    Bairro: "CENTRO",
    Rua: "TOCANTINS",
    latitude: -14.5260614,
    longitude: -49.13756559999999,
  },
  {
    Parceiro: "CASA DO DOCE",
    Cidade: "Rio Quente",
    Estado: "GO",
    CEP: "75667971",
    Bairro: "JARDIM ESPLANADA",
    Rua: "MARANHAO S/N QUADRA 9 LOTE 9",
    latitude: -17.8086529,
    longitude: -48.7605938,
  },
  {
    Parceiro: "SUPER LEO SUPERMERCADO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74393480",
    Bairro: "PRQ SANTA RITA",
    Rua: "SR7",
    latitude: -16.7219721,
    longitude: -49.3692469,
  },
  {
    Parceiro: "FRUTA DO VALE",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74675090",
    Bairro: "JARDIM GUANABARA",
    Rua: "BR-153",
    latitude: -16.6243541,
    longitude: -49.2037467,
  },
  {
    Parceiro: "NILZAN LOPES ROSAL",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74823450",
    Bairro: "SETOR BELA VISTA",
    Rua: "S 4",
    latitude: -16.7167982,
    longitude: -49.2611217,
  },
  {
    Parceiro: "JL-MALL COMERCIO E DISTRIBUICAO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74255070",
    Bairro: "JARDIM AMERICA",
    Rua: "C208",
    latitude: -16.7060324,
    longitude: -49.2855227,
  },
  {
    Parceiro: "UNIAO SUPERMERCADO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75906712",
    Bairro: "JARDIM SAO TOMAZ II",
    Rua: "BOLIVIA",
    latitude: -17.823126191047223,
    longitude: -50.93181671696528,
  },
  {
    Parceiro: "MERCADAO, CESTARIA E DECORACAO",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74255220",
    Bairro: "JARDIM AMERICA",
    Rua: "T 9, ESQ C/ RUA FLEMINGTON",
    latitude: -16.7129409,
    longitude: -49.2994927,
  },
  {
    Parceiro: "OFICINA DO CRACHA",
    Cidade: "Campinas",
    Estado: "SP",
    CEP: "13026241",
    Bairro: "JARDIM PROENCA",
    Rua: "MONTE CASTELO",
    latitude: -22.9127808,
    longitude: -47.0474879,
  },
  {
    Parceiro: "ALPHA BABY",
    Cidade: "Tiete",
    Estado: "SP",
    CEP: "18530000",
    Bairro: "JARDIM BONANZA",
    Rua: "ARRASTAO",
    latitude: -23.0972469,
    longitude: -47.7144618,
  },
  {
    Parceiro: "EMBALAGENS TOCANTINS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74535280",
    Bairro: "SETOR COIMBRA",
    Rua: "210",
    latitude: -16.6753717,
    longitude: -49.2852097,
  },
  {
    Parceiro: "AGROINDUSTRIAL FRUTO DA TERRA LTDA",
    Cidade: "Vista Alegre Do Alto",
    Estado: "SP",
    CEP: "15920000",
    Bairro: "BOA VISTA DA ONCA",
    Rua: "SP 323 KM 32 SENTIDO VISTA ALEGRE DO ALTO A PIRANGI",
    latitude: -21.1724617,
    longitude: -48.62956699999999,
  },
  {
    Parceiro: "A.R REPRESENTACOES",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74563220",
    Bairro: "SET CRIMEIA OESTE",
    Rua: "GOIÁS",
    latitude: -16.6724204,
    longitude: -49.2846857,
  },
  {
    Parceiro: "SUPERMERCADO GOURMET",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75103540",
    Bairro: "CAMPOS ELÍSIOS",
    Rua: "BENJAMIN CONSTANT",
    latitude: -16.3614338,
    longitude: -48.9159326,
  },
  {
    Parceiro: "START ARTESANATOS",
    Cidade: "Lindoia",
    Estado: "SP",
    CEP: "13950000",
    Bairro: "JARDIM ACACIAS",
    Rua: "ERMELINDO CONTI",
    latitude: -22.5225003,
    longitude: -46.6477059,
  },
  {
    Parceiro: "SUPERMERCADO REIS",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75084370",
    Bairro: "BRO SAO CARLOS",
    Rua: "PRESIDENTE JANIO QUADROS",
    latitude: -14.6882517,
    longitude: -41.6803247,
  },
  {
    Parceiro: "SUPER ZE",
    Cidade: "Figueiropolis",
    Estado: "TO",
    CEP: "77465000",
    Bairro: "CENTRO",
    Rua: "BERNARDO SAYÃO",
    latitude: -7.8792141,
    longitude: -48.8925268,
  },
  {
    Parceiro: "SUPER ZE",
    Cidade: "Figueiropolis",
    Estado: "TO",
    CEP: "77465000",
    Bairro: "CENTRO",
    Rua: "BERNARDO SAYÃO",
    latitude: -7.8792141,
    longitude: -48.8925268,
  },
  {
    Parceiro: "SUPERMERCADO SICKEIRA",
    Cidade: "Silvania",
    Estado: "GO",
    CEP: "75180000",
    Bairro: "SETOR CENTRAL",
    Rua: "HENRIQUE SILVA",
    latitude: -16.6564034,
    longitude: -48.6042183,
  },
  {
    Parceiro: "SUPERMERCADO RODRIGUES",
    Cidade: "Pires Do Rio",
    Estado: "GO",
    CEP: "75200000",
    Bairro: "SANTA CECILIA",
    Rua: "JOSE REZENDE ALEXANDRE",
    latitude: -17.2953207,
    longitude: -48.2712982,
  },
  {
    Parceiro: "GOIAS PECAS",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74323260",
    Bairro: "VILA MAUA",
    Rua: "HERMOGENES MARQUES",
    latitude: -16.6938534,
    longitude: -49.3169467,
  },
  {
    Parceiro: "SABOR SUPREMO",
    Cidade: "Inhumas",
    Estado: "GO",
    CEP: 75400000,
    Bairro: "VILA AMERICA",
    Rua: "QUADRAD LOTE 06",
    latitude: -16.3679577,
    longitude: -49.49935929999999,
  },
  {
    Parceiro: "SUPERMERCADO NOTA 10",
    Cidade: "Pirenopolis",
    Estado: "GO",
    CEP: "72980000",
    Bairro: "VILA NHONHO GODINHO",
    Rua: "JOAO VEIGA",
    latitude: -15.8540282,
    longitude: -48.9474054,
  },
  {
    Parceiro: "ARMAZEM PIRENEUS",
    Cidade: "Pirenopolis",
    Estado: "GO",
    CEP: "72980000",
    Bairro: "CENTRO",
    Rua: "BENJAMIN CONSTANT",
    latitude: -15.8563266,
    longitude: -48.9587211,
  },
  {
    Parceiro: "SUPERMERCADO SANTA FE - S A SANTANA",
    Cidade: "Goiânia",
    Estado: "GO",
    CEP: "74780500",
    Bairro: "PRQ DAS AMENDOEIRAS",
    Rua: "PAULO ALVES DA COSTA",
    latitude: -16.6559459,
    longitude: -49.1888804,
  },
  {
    Parceiro: "SUPER BEM BARATO - PORTO NACIONAL",
    Cidade: "Porto Nacional",
    Estado: "TO",
    CEP: "77500000",
    Bairro: "DISTRITO DE LUZIMANGUES",
    Rua: "TO-080, MARGINAL NORTE, JARDINS DO LAGO",
    latitude: -10.1924121,
    longitude: -48.4602251,
  },
  {
    Parceiro: "SUPER BEM BARATO - PALMAS",
    Cidade: "Palmas",
    Estado: "TO",
    CEP: "77064604",
    Bairro: "CENTRO (TAQUARALTO)",
    Rua: "34",
    latitude: -10.3387536,
    longitude: -48.2947199,
  },
  {
    Parceiro: "OPA SANDUBA",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75110770",
    Bairro: "JUNDIAI",
    Rua: "MINAS GERAIS",
    latitude: -16.3337086,
    longitude: -48.9379168,
  },
  {
    Parceiro: "JORGE MERCADO",
    Cidade: "Fatima Do Sul",
    Estado: "MS",
    CEP: "79700000",
    Bairro: "VILA RIBEIRO",
    Rua: "PRESIDENTE VARGAS",
    latitude: -22.3853436,
    longitude: -54.51211869999999,
  },
  {
    Parceiro: "BEM BARATO ATACADISTA",
    Cidade: "Porto Nacional",
    Estado: "TO",
    CEP: "77500000",
    Bairro: "JARDIM EUROPA",
    Rua: "TO-080, MARGINAL NORTE, JARDINS DO LAGO",
    latitude: -10.1899279,
    longitude: -48.5974881,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - SP - JARDIM CANADA",
    Cidade: "Ribeirao Preto",
    Estado: "SP",
    CEP: "14024260",
    Bairro: "JARDIM CANADA",
    Rua: "PROFESSOR JOAO FIUSA",
    latitude: -21.2003535,
    longitude: -47.8146975,
  },
  {
    Parceiro: "SUPERMERCADO ELDORADO",
    Cidade: "Porangatu",
    Estado: "GO",
    CEP: "76550000",
    Bairro: "VILA SOARES",
    Rua: "HUM,",
    latitude: -13.4502912,
    longitude: -49.1428378,
  },
  {
    Parceiro: "MENOR PRECO SUPERMERCADO",
    Cidade: "Alvorada",
    Estado: "TO",
    CEP: "77480000",
    Bairro: "SETOR OESTE",
    Rua: "FORTALEZA",
    latitude: -3.7327203,
    longitude: -38.5270134,
  },
  {
    Parceiro: "COMERCIAL REIS - RIO VERDE - VILA MARIA",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75905390",
    Bairro: "VILA MARIANA",
    Rua: "RECIFE",
    latitude: -17.786499,
    longitude: -50.9067894,
  },
  {
    Parceiro: "COMERCIAL REIS - RIO VERDE - SANTO ANDRE",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75901695",
    Bairro: "VILA SANTO ANDRE",
    Rua: "AVELINO DE FARIA",
    latitude: -17.7903702,
    longitude: -50.929491,
  },
  {
    Parceiro: "COMERCIAL REIS - RIO VERDE - SETOR POPULAR",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75903470",
    Bairro: "POPULAR",
    Rua: "273",
    latitude: -17.7813687,
    longitude: -50.9206864,
  },
  {
    Parceiro: "SUPERMERCADO COMETA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60760312",
    Bairro: "MONDUBIM",
    Rua: "BERNARDO MANUEL",
    latitude: -3.8395996,
    longitude: -38.5641123,
  },
  {
    Parceiro: "AMBROSIO REPRESENTACAO E DISTRIBUICAO",
    Cidade: "Betim",
    Estado: "MG",
    CEP: "32604682",
    Bairro: "SENHORA DAS GRACAS",
    Rua: "PROFESSOR PEDRO",
    latitude: -19.9413942,
    longitude: -44.1956843,
  },
  {
    Parceiro: "CITRO5 ATACAREJO - SAO JOAO",
    Cidade: "Mineiros",
    Estado: "GO",
    CEP: "75836046",
    Bairro: "SAO JOAO",
    Rua: "SAO JOAO",
    latitude: -17.5600557,
    longitude: -52.5492522,
  },
  {
    Parceiro: "FRUTT CENTER",
    Cidade: "Caldas Novas",
    Estado: "GO",
    CEP: "75680001",
    Bairro: "NOVA VILA MARIA",
    Rua: "C87",
    latitude: -17.7388498,
    longitude: -48.6254189,
  },
  {
    Parceiro: "DISTRIBUIDORA SOL NASCENTE",
    Cidade: "Barra Do Garças",
    Estado: "MT",
    CEP: "78600000",
    Bairro: "SETOR CRISTINO CORTES",
    Rua: "JOSE RODRIGUES DOS SANTOS",
    latitude: -15.8810755,
    longitude: -52.2640089,
  },
  {
    Parceiro: "EUDETE ALVES SANTOS 64897338115",
    Cidade: "Maraba",
    Estado: "PA",
    CEP: "68504100",
    Bairro: "NOVA MARABA",
    Rua: "RURAL",
    latitude: -5.3451205,
    longitude: -49.0781208,
  },
  {
    Parceiro: "HIPERSENNA",
    Cidade: "Parauapebas",
    Estado: " PA",
    CEP: "68515000",
    Bairro: "LOTEAMENTO BEIRA RIO",
    Rua: "PERIMETRAL SUL",
    latitude: -6.079430599999999,
    longitude: -49.8838729,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - ALDEOTA - ILDEFONSO ALBANO",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60115001",
    Bairro: "ALDEOTA",
    Rua: "ILDEFONSO ALBANO",
    latitude: -3.7346953,
    longitude: -38.5136811,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - PARQUE MANIBURA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60822130",
    Bairro: "PARQUE MANIBURA",
    Rua: "OLIVEIRA PAIVA",
    latitude: -3.7955289,
    longitude: -38.4901575,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - CENTRO - PINTO MADEIRA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60150000",
    Bairro: "CENTRO",
    Rua: "PINTO MADEIRA",
    latitude: -3.7319753,
    longitude: -38.5186104,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - CIDADE DOS FUNCIONÁRIOS",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60822500",
    Bairro: "CIDADE DOS FUNCIONARIOS",
    Rua: "JULIO LIMA",
    latitude: -3.7960449,
    longitude: -38.4990444,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - SETOR GENTIL MEIRELES",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60170265",
    Bairro: "SETOR GENTIL MEIRELES",
    Rua: "SENADOR VIRGILIO TAVORA",
    latitude: -3.7345217,
    longitude: -38.49224,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - BARRA DO CEARA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60312060",
    Bairro: "BARRA DO CEARA",
    Rua: "PRESIDENTE CASTELO BRANCO",
    latitude: -3.70075,
    longitude: -38.58581,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - CENTRO - J DA PENHA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60110120",
    Bairro: "CENTRO",
    Rua: "J DA PENHA",
    latitude: -3.7373339,
    longitude: -38.5202092,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - VILA FÁTIMA - TREZE DE MAIO",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60040530",
    Bairro: "VILA FÁTIMA",
    Rua: "TREZE DE MAIO",
    latitude: -3.7490497,
    longitude: -38.5294928,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - DIONISIO TORRES",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60135203",
    Bairro: "DIONISIO TORRES",
    Rua: "ANTONIO SALES",
    latitude: -3.7440426,
    longitude: -38.5079914,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - PAPIÇU",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60175657",
    Bairro: "PAPICU",
    Rua: "ENGENHEIRO SANTANA JUNIOR",
    latitude: -3.7378675,
    longitude: -38.4835135,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - ALDEOTA - PADRE ANTONIO TOMAS",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60140155",
    Bairro: "ALDEOTA",
    Rua: "PADRE ANTONIO TOMAS",
    latitude: -3.7433798,
    longitude: -38.4890734,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - ALDEOTA - TIBURCIO CAVALCANTE",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60125045",
    Bairro: "ALDEOTA",
    Rua: "TIBURCIO CAVALCANTE",
    latitude: -3.7402195,
    longitude: -38.5039091,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - PARQUELANDIA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60455410",
    Bairro: "PARQUELANDIA",
    Rua: "JOVITA FEITOSA",
    latitude: -3.7404526,
    longitude: -38.5602726,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - VILA FÁTIMA - DEPUTADO OSWALDO",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60411260",
    Bairro: "VILA FÁTIMA",
    Rua: "DEPUTADO OSWALDO STUDART",
    latitude: -3.7553137,
    longitude: -38.5297807,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - ALDEOTA - SENADOR VIRGILO TAVORA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60170251",
    Bairro: "ALDEOTA",
    Rua: "SENADOR VIRGILIO TAVORA",
    latitude: -3.7456007,
    longitude: -38.4957256,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - MARAPONGA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60710683",
    Bairro: "MARAPONGA",
    Rua: "GODOFREDO MACIEL",
    latitude: -3.7918263,
    longitude: -38.5671378,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - SAPIRANGA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60834005",
    Bairro: "SAPIRANGA / COITE",
    Rua: "EDILSON BRASIL SOARES",
    latitude: -3.7884179,
    longitude: -38.4681841,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - COITE",
    Cidade: "Eusebio",
    Estado: "CE",
    CEP: "61760000",
    Bairro: "SAPIRANGA / COITE",
    Rua: "EUSEBIO DE QUEIROZ",
    latitude: -3.8792799,
    longitude: -38.4611636,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - SETOR SERRINHA",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60741575",
    Bairro: "SETOR SERRINHA",
    Rua: "DOUTOR SILAS MUNGUBA",
    latitude: -3.7914778,
    longitude: -38.5372598,
  },
  {
    Parceiro: "SUPERMERCADO COMETA - DAMAS",
    Cidade: "Fortaleza",
    Estado: "CE",
    CEP: "60426052",
    Bairro: "DAMAS",
    Rua: "DESEMBARGADOR PRAXEDES",
    latitude: -3.7567707,
    longitude: -38.5531553,
  },
  {
    Parceiro: "SUPERMERCADO NERAO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74920792",
    Bairro: "SETOR PARQUE FLAMBOYANT",
    Rua: "PB 1",
    latitude: -16.7406934,
    longitude: -49.2214398,
  },
  {
    Parceiro: "CASEIRA ALIMENTOS",
    Cidade: "Trindade",
    Estado: "GO",
    CEP: "75389386",
    Bairro: "RESIDENCIAL MONTE CRISTO",
    Rua: "M 18",
    latitude: -16.6806031,
    longitude: -49.4825992,
  },
  {
    Parceiro: "EMPÓRIO TRADIÇÃO",
    Cidade: "Rio Verde",
    Estado: "GO",
    CEP: "75903380",
    Bairro: "JARDIM GOIAS",
    Rua: "OSÓRIO COELHO DE MORAES",
    latitude: -17.7856922,
    longitude: -50.9169368,
  },
  {
    Parceiro: "SUPERMERCADO NERAO",
    Cidade: "Aparecida De Goiânia",
    Estado: "GO",
    CEP: "74920792",
    Bairro: "SETOR PARQUE FLAMBOYANT",
    Rua: "PB 1",
    latitude: -16.7406934,
    longitude: -49.2214398,
  },
  {
    Parceiro: "TRIANG FRUIT",
    Cidade: "Anápolis",
    Estado: "GO",
    CEP: "75132180",
    Bairro: "DISTRITO AGROINDUSTRIAL DE ANAPOLIS-DAIA",
    Rua: "VP-E1",
    latitude: -16.3863119,
    longitude: -48.9524839,
  },
  {
    Parceiro: "SUPERMERCADO POPULAR",
    Cidade: "Rubiataba",
    Estado: "GO",
    CEP: "76350000",
    Bairro: "CENTRO",
    Rua: "AROEIRA",
    latitude: -15.162756,
    longitude: -49.8019376,
  },
  {
    Parceiro: "HIPERSENNA - CD PA",
    Cidade: "Parauapebas",
    Estado: "PA",
    CEP: "68515000",
    Bairro: "LOTEAMENTO BEIRA RIO",
    Rua: "BARAO DO RIO BRANCO",
    latitude: -6.079430599999999,
    longitude: -49.8838729,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - CABO DE SANTO AGOSTINHO - DIST. INDUSTRIAL",
    Cidade: "Cabo De Santo Agostinho",
    Estado: "PE",
    CEP: "54503900",
    Bairro: "DISTRITO INDUSTRIAL DIPER",
    Rua: "BR-101 SUL 00KM 96,4",
    latitude: -8.3289001,
    longitude: -35.106843,
  },
  {
    Parceiro: "MOEMA DISTRIBUIDORA",
    Cidade: "Japaraiba",
    Estado: "MG",
    CEP: "35580000",
    Bairro: "ANDRE E CHIQUINHA",
    Rua: "JOSINA MARCIANO",
    latitude: -20.1446229,
    longitude: -45.5019755,
  },
  {
    Parceiro: "PÃO DE AÇÚCAR - CABO DE SANTO AGOSTINHO - DIST. INDUSTRIAL",
    Cidade: "Cabo De Santo Agostinho",
    Estado: "PE",
    CEP: "54503900",
    Bairro: "DISTRITO INDUSTRIAL DIPER",
    Rua: "BR-101 SUL 00KM 96,4",
    latitude: -8.3289001,
    longitude: -35.106843,
  },
];

// QUANDO FOR RETIRAR LOJAS PASSAR O ARRAY COM AS LOJAS QUE PRECISAM SER REMOVIDAS E RODAR ESSA FUNÇÃO E COPIAR
// O OBJETO QEU RETORNA E SUBSTITUIR O OBJETO STORES
// function removeItemsFromArray(mainArray, itemsToRemove) {
//   return mainArray.filter(item => !itemsToRemove.some(removeItem =>
//     JSON.stringify(item) === JSON.stringify(removeItem)
//   ));
// }

// const updatedStores = removeItemsFromArray(stores, removeStore);

// console.log(updatedStores)
